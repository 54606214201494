<template>
    <div class="vue-csv-uploader">
        <v-app>
            <div class="table-responsive">
                <v-data-table
                v-model="selectedItems"
                :headers="headers"
                :items="manualpurchasepricesummations"
                item-key="id"
                show-select
                class="elevation-1"
                :options="footerProps"
                dense
                >
                    <template
                    v-slot:top
                    > 
                        <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
                            <v-container
                            class="pa-6"
                            >
                            <v-row>
                                <v-col
                                cols="12"
                                md="12"
                                >
                                <v-autocomplete
                                v-model="profit_data.platforms_ids"
                                :items='platforms'
                                item-text='name'
                                item-value="id"
                                label='Plattform'
                                auto-select-first
                                clearable
                                deletable-chips
                                multiple
                                @change="toggleAllSelections"
                                ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                cols="12"
                                md="3"
                                >
                                <v-text-field
                                dense
                                v-model="profit_data.arwg_from"
                                label="ARWG von"
                                type="number" min="1" step="1"
                                :rules="[rules.required, rules.arwg]"
                                required
                                ></v-text-field>
                                </v-col>

                                <v-col
                                cols="12"
                                md="3"
                                >
                                <v-text-field
                                dense
                                v-model="profit_data.arwg_to"
                                label="ARWG bis"
                                type="number" min="1" step="1"
                                :rules="[rules.required, rules.arwg]"
                                required
                                ></v-text-field>
                                </v-col>
                                <v-col
                                cols="12"
                                md="3"
                                >
                                <v-text-field
                                dense
                                v-model="profit_data.price"
                                label="Preis"
                                suffix="€"
                                :rules="[rules.required, rules.price]"
                                required
                                ></v-text-field>
                                </v-col>
                                <v-col
                                cols="12"
                                md="3"
                                >
                                    <v-btn
                                    dense
                                    type="submit"
                                    color="success"
                                   
                                    :disabled="!valid || profit_data.platforms_ids.length == 0"
                                    >
                                    Speichern
                                    </v-btn>
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-form>
                        <v-btn
                        small
                        color="error"
                        @click="deleteSelectedItems"
                        v-if="selectedItems.length > 0"
                        >
                            <v-icon left>
                            mdi-trash-can-outline
                            </v-icon>
                            Ausgewählte löschen
                        </v-btn>
                        <v-dialog
                        v-model="dialog"
                        max-width="500px"
                        >
                        <v-card>
                            <v-card-title class="primary text-white" >
                                <span class="text-h5 ">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="2"
                                        md="2"
                                    >
                                        <v-text-field
                                        v-model="editedItem.id"
                                        label="Id"
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="10"
                                        md="10"
                                    >
                                        <v-autocomplete
                                        v-model="editedItem.platform_id"
                                        :items='platforms'
                                        item-text='name'
                                        item-value="id"
                                        label='Plattform'
                                        readonly
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field
                                        v-model="editedItem.ARWG_from"
                                        label="ARWG von"
                                        type="number" min="1" step="1"
                                        :rules="[rules.required, rules.arwg]"
                                        required
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field
                                        v-model="editedItem.ARWG_to"
                                        label="ARWG bis"
                                        type="number" min="1" step="1"
                                        :rules="[rules.required, rules.arwg]"
                                        required
                                        readonly
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field
                                        v-model="editedItem.price"
                                        label="Preis"
                                        type="number" min="1" step="1"
                                        suffix="€"
                                        :rules="[rules.required, rules.price]"
                                        required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                small
                                @click="close"
                                >
                                    Abbrechen
                                </v-btn>
                                <v-btn
                                small
                                color="success"
                                @click="save"
                                >
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5" style="word-break: break-word; color:red;" v-if="deleteSelected" >Sind Sie sicher, dass Sie diese ausgewählten Summierungen löschen möchten?</v-card-title>
                            <v-card-title class="text-h5" style="word-break: break-word; color:red;" v-if="deleteSelected == false" >Sind Sie sicher, dass Sie diese Summierung löschen möchten?</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Nein</v-btn>
                            <v-btn color="red darken-1" text @click="deleteItemConfirm">JA</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    </template>
                    <template v-slot:header.plaformname	="{ header }">
                        {{ header.text }}
                        <v-menu v-model="platformFilter" offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="platform? 'primary' : ''">
                                mdi-filter
                                </v-icon>
                            </v-btn>
                            </template>
                            <div style="background-color: white; width: 280px">
                            <v-text-field v-model="platform" class="pa-4" type="numeric" label="Geben Sie den Suchbegriff ein"
                                 persistent-hint :autofocus="true"
                                @keydown.enter.prevent="filter">
                            </v-text-field>
                            <v-btn text @click="platformFilter = false">
                                <v-icon dark>
                                mdi-close
                                </v-icon>
                            </v-btn>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    <v-btn @click="platform = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                    <v-icon dark>
                                        mdi-filter-off
                                    </v-icon>
                                    </v-btn>
                                </span>
                                </template>
                                <span>Filter aufheben</span>
                            </v-tooltip>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                mdi-database-search
                                </v-icon>
                            </v-btn>
                            </div>
                        </v-menu>
                     </template>
                     <template v-slot:header.ARWG_from="{ header }">
                        {{ header.text }}
                        <v-menu v-model="arwg_fromFilter" offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="arwg_from ? 'primary' : ''">
                                mdi-filter
                                </v-icon>
                            </v-btn>
                            </template>
                            <div style="background-color: white; width: 280px">
                            <v-text-field v-model="arwg_from" class="pa-4" type="numeric" label="Geben Sie den Suchbegriff ein"
                            persistent-hint :autofocus="true"
                            @keydown.enter.prevent="filter">
                            </v-text-field>
                            <v-btn text @click="arwg_fromFilter = false">
                                <v-icon dark>
                                mdi-close
                                </v-icon>
                            </v-btn>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    <v-btn @click="arwg_from = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                    <v-icon dark>
                                        mdi-filter-off
                                    </v-icon>
                                    </v-btn>
                                </span>
                                </template>
                                <span>Filter aufheben</span>
                            </v-tooltip>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                mdi-database-search
                                </v-icon>
                            </v-btn>
                            </div>
                        </v-menu>
                     </template>
                     <template v-slot:header.ARWG_to="{ header }">
                        {{ header.text }}
                        <v-menu v-model="arwg_toFilter" offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="arwg_to ? 'primary' : ''">
                                mdi-filter
                                </v-icon>
                            </v-btn>
                            </template>
                            <div style="background-color: white; width: 280px">
                            <v-text-field v-model="arwg_to" class="pa-4" type="numeric" label="Geben Sie den Suchbegriff ein"
                                persistent-hint :autofocus="true"
                                @keydown.enter.prevent="filter">
                            </v-text-field>
                            <v-btn text @click="arwg_toFilter = false">
                                <v-icon dark>
                                mdi-close
                                </v-icon>
                            </v-btn>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    <v-btn @click="arwg_to = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                    <v-icon dark>
                                        mdi-filter-off
                                    </v-icon>
                                    </v-btn>
                                </span>
                                </template>
                                <span>Filter aufheben</span>
                            </v-tooltip>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                mdi-database-search
                                </v-icon>
                            </v-btn>
                            </div>
                        </v-menu>
                     </template>
                    <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        color="blue"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        color="red"
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                    </template>
                    <template v-slot:item.price="{ item }">
                        {{ convertDoubleToCurrency(item.price) }}
                    </template>
                </v-data-table>  
            </div>
        </v-app>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/manualpurchasepricesummations'
            },

            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            validation: {
                type: Boolean,
                default: true,
            },
        },

        data: () => ({
            platforms: [],
            rules: {
                required: value => !!value || 'Erforderlich',
                counter: value => value.length <= 20 || 'Max 20 characters',
                arwg: value => {
                    return /^\d+$/.test(value) || 'Ungültig'
                },
                price: value => {
                   return /^[\+\-]?\d+(?:[\.,]\d{1,2})?$/.test(value) || 'Ungültig'
                },
            },
            filters: [],
                options: {
                page: 1,
                itemsPerPage: 50,
                sortDesc: [false],
                mustSort: false,
                multiSort: false,
                sort: '',
                filters: null
            },
            enableSubmit: false,
            platform: null,
            platformFilter: false,
            arwg_from: null,
            arwg_to: null,
            arwg_fromFilter: false,
            arwg_toFilter: false,
            valid: false,
            selectedItems: [],
            selectedPlatforms: [],
            headers: [
            {text: 'Id', value: 'id'},
            {text: 'Plattform', value: 'plaformname'},
            { text: 'ARWG von', value: 'ARWG_from' },
            { text: 'ARWG bis', value: 'ARWG_to' },
            { text: 'Preis', value: 'price' },
            { text: 'Aktionen', value: 'actions' },
            ],

            footerProps: {
            'items-per-page-options': [-1],
            'show-current-page': true,
            'show-first-last-page': true,
            disableItemsPerPage: false,
            itemsPerPage: -1,
            itemsPerPageText: 'Einträge pro Seite',
            itemsPerPageAllText: 'Alle',
            },

            profit_data:{
                platforms_ids:[],
                arwg_from: null,
                arwg_to: null,
                price: null
            },
            manualpurchasepricesummations: [],
            dialog: false,
            dialogDelete: false,
            deleteSelected: false,
            editedItem: {
                id: 0,
                platform_id: null,
                ARWG_from: 0,
                ARWG_to: 0,
                price: 0,
            },
            defaultItem: {
                id: 0,
                platform_id: null,
                ARWG_from: 0,
                ARWG_to: 0,
                price: 0,
            },
        }),

        created() {
            this.filters = new Array();
            this.getPlatforms();
            this.getManualPurchasePriceSummations();
        },

        methods: {

            close () {
                this.dialog = false
                this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                })
            },

            closeDelete () {
                this.deleteSelected = false;
                this.dialogDelete = false
                this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                })
            },

            deleteItemConfirm () {
                const _this = this;
                if(this.deleteSelected)
                {  
                    axios.post('/admin/manualpurchasepricesummation/deletemultiple',
                       { ids: _this.selectedItems.map(obj => obj.id)},
                    ).then(response => {
                    _this.showMsgBoxOne(response, true);
                    }).catch(response => {
                        _this.showMsgBoxOne(response, false);
                        _this.catch(response);
                    }).finally(response => {
                        _this.finally(response);
                    });
                    _this.selectedItems = [];
                    _this.closeDelete()
                    return;
                }

                axios.delete(_this.url +'/'+ _this.editedItem.id,
                ).then(response => {
                   _this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.showMsgBoxOne(response, false);
                     _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
                this.closeDelete()
            },

            save() {
                const _this = this;
                axios.post(_this.url,
                     _this.editedItem,
                ).then(response => {
                   _this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.showMsgBoxOne(response, false);
                     _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
                this.close();
            },

            editItem (item) {
                // this.editedIndex = this.desserts.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem (item) {
                // this.editedIndex = this.desserts.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteSelectedItems(){
                this.deleteSelected = true;
                this.dialogDelete = true
            },

            toggleAllSelections() {
                const found = this.profit_data.platforms_ids.length == 1 && this.profit_data.platforms_ids.filter(id => id == 0).length > 0;
                if (found) {
                    // Alle Elemente auswählen
                    this.profit_data.platforms_ids = this.platforms.map(item => item.id);
                } 
            },

            async clearFilter() {
                this.filter();
            },

            convertDoubleToCurrency(value) {
                return value === null ? '' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
            },

            getManualPurchasePriceSummations: function(){
                axios.get('/admin/getmanualpurchasepricesummations/', { params: this.options })
                .then(function (response) {
                    this.manualpurchasepricesummations = response.data;
                }.bind(this));
            },

            getPlatforms: function(){
                axios.get('/admin/getallplatforms')
                .then(function (response) {
                    this.platforms = response.data;
                    this.platforms.unshift({id:0, name: "Alle Plattformen"});
                }.bind(this));
            },

            submitForm() {
                const _this = this;
                if(_this.profit_data.platforms_ids.length == 0)
                {
                    var callback = {
                        data: "Sie müssen Plattformen auswählen!",
                        status: 400,
                        typ: 'warning'
                    }
                    _this.showMsgBoxOne(callback, false);
                    return;
                }
                _this.profit_data.platforms_ids = _this.profit_data.platforms_ids.filter((id) => id > 0);
                axios.post(_this.url,
                     _this.profit_data,
                ).then(response => {
                    _this.showMsgBoxOne(response, true);
                    _this.profit_data.platforms_ids = [];
                    _this.profit_data.arwg_from = null;
                    _this.profit_data.arwg_to = null;
                    _this.profit_data.price = null;
                }).catch(response => {
                    _this.showMsgBoxOne(response, false);
                     _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },

            showMsgBoxOne(callback, change) {
                if(callback.status > 200){
                        this.$bvModal.msgBoxOk(callback.data, {
                        title: 'FEHLER',
                        headerBgVariant: 'danger',
                        headerTextVariant: 'light',
                        titleVariant: 'danger',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'danger',
                        size:'lg',
                        okVariant: 'warning'
                         });
                    return;
                }
                this.$bvModal.msgBoxOk(callback.data, {
                        title: 'MELDUNG',
                        headerBgVariant: 'success',
                        headerTextVariant: 'light',
                        titleVariant: 'success',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'success',
                        size:'lg',
                        okVariant: 'success'
                         })
                .then(value => {
                    if(change)
                    {
                       this.getManualPurchasePriceSummations();
                    }
                })
                .catch(err => {
                   
                })
            },

            showError(mp) {
                if(mp.prozent == null)
                 {
                     this.$bvModal.msgBoxOk('Prozent darf nicht leer sein!'+ mp, {
                        title: 'FEHLER',
                        headerBgVariant: 'danger',
                        headerTextVariant: 'light',
                        titleVariant: 'danger',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'danger',
                        size:'lg',
                        okVariant: 'warning'
                         });
                 }
            },

            validate () {
                this.$refs.form.validate()
            },

            filter() {
                this.filters.length = 0;
                if (this.platform) {
                    this.filters.push(new Array("tplatforms.name", 'LIKE', '%'+this.platform+'%'));
                }
                if (this.arwg_from) {
                    this.filters.push(new Array('ARWG_from', '=', this.arwg_from));
                }
                if (this.arwg_to) {
                    this.filters.push(new Array('ARWG_to', '=', this.arwg_to));
                }
                this.options.filters = this.filters;
                this.getManualPurchasePriceSummations();
            }
        },
        watch: {

        },
        computed: {
            formTitle () {
                return 'Bearbeiten';
            },
        },
    };
</script>