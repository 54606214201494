<template>
    <v-app>
        <v-data-table :headers="headers" :items="artikelconfigs" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}'
        }">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Artikelkonfiguration</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on" v-if="artikelconfigs.size === 0">
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-app-bar dark color="primary">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field 
                                            v-model="editedItem.count_deviation"
                                            type="number"
                                            min="0"
                                            step="0.1"
                                            prepend-icon="mdi-percent"
                                            label="Abweichung in %"
                                            hint="Wenn die Artikelanzahl diese Prozent unterschritten wird dann wird das Importieren gestoppt und eine Warnmeldung gesendet."
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field label="Beschreibung" v-model="editedItem.description"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-switch
                                            v-model="editedItem.status"
                                            color="green"
                                            :label="`Status: ${editedItem.status > 0 ? 'Der Importdienst ist aktiviert ' : 'Der Importdienst ist deaktiviert! Die Stammartikel werden nicht importiert!'}`"
                                            hint="Startet oder Stopt den Importdienst"
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="success" @click="save">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                </v-tooltip>
            </template>
             <template v-slot:item.count_deviation="{ item }">
                {{ item.count_deviation | formatDecimal }}
            </template>
            <template v-slot:item.status="{ item }">
                <v-tooltip bottom v-if="item.status > 0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="green" v-bind="attrs" v-on="on">
                            mdi-play-circle
                        </v-icon>
                    </template>
                    <span> Der Importdienst ist aktiviert</span>
                </v-tooltip>
                <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="red" v-bind="attrs" v-on="on">
                            mdi-stop-circle
                        </v-icon>
                    </template>
                    <span> Der Importdienst ist deaktiviert</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </v-app>
</template>
<script>
export default {
    props: {
        value: Array,
        url: {
            type: String,
            default: '/admin/artikelconfigs'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
    },

    data: () => ({
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        headers: [
            { text: 'Abweichung %', value: 'count_deviation' },
            { text: 'Importierte Anzahl', value: 'last_imported_count' },
            { text: 'Importiert am', value: 'last_imported_at' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Status', value: 'status' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
        success: false,
        artikelconfigs: [],
        editedItem: {
            id: 0,
            count_deviation: 0,
            default_count: 0,
            description: null,
            status: 1
        },
        defaultItem: {
            id: 0,
            count_deviation: 0,
            default_count: 0,
            description: null,
            status: 1
        },
        boxOne: '',
    }),

    computed: {
        formTitle() {
            return this.editedItem.id === 0 ? 'Artikelkonfiguration hinzufügen' : 'Artikelkonfiguration bearbeiten'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.getArtikelConfigs();
    },

    filters: {
        // you can call this whatever you want to
        formatDecimal(value) {
            return value.toLocaleString();
        }
    },

    methods: {
        async getArtikelConfigs() {
            axios.get('/admin/artikelconfigsdata')
                .then(function (response) {
                    this.artikelconfigs = response.data;
                }.bind(this));
        },

        editItem(item) {
            axios.get(this.url + '/' + item.id + '/edit')
                .then(function (response) {
                    this.editedItem = response.data;
                    this.dialog = true
                }.bind(this));
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem; //Object.assign({}, this.defaultItem)
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;//Object.assign({}, this.defaultItem)
            })
        },

        save() {
            const _this = this;
            if (this.editedItem.id > 0) {
                axios.put(this.url + '/' + this.editedItem.id,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }
            // Hide the modal manually
            this.$nextTick(() => {
                // this.resetModal();
                this.$bvModal.hide('modal-prevent-closing')
            })
            this.close()
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
            .then(value => {
                if (change) {
                    this.getArtikelConfigs();
                }
            })
            .catch(err => {
            })
        },
    },
}
</script>