<template>
    <v-app>
        <div class="vue-csv-uploader">
            <v-form>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platform.name" :rules="nameRules" label="Name" dense
                            required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platform.matchcode" :rules="nameRules" label="Namenkürzel"
                            dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platform.debitorNr" :rules="nameRules" label="Debitornummer" dense
                            required></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-autocomplete v-model="platform.supplierPath" :items="directories"
                            label="Dateipfad der Plattform" required :no-data-text="'keine Daten verfügbar'">
                            <template v-slot:prepend>
                                <v-tooltip text="Tooltip">
                                    <template v-slot:activator="{ props }">
                                        <v-btn icon color="green" @click="refreshSupplierDirectories">
                                            <v-icon v-bind="props">mdi-cached</v-icon>
                                        </v-btn>
                                    </template>
                                </v-tooltip>
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-autocomplete v-model="platform.country" :items="countryoptions" item-value="code"
                            item-text="country" label="Land" required></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-autocomplete v-model="platform.currency" :items="currencyoptions" item-value="iso_code"
                            item-text="iso_code" label="Währung" required></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-autocomplete v-model="platform.blockingartikelpricecolumn" :items="artikelcolumns"
                            item-value="label" item-text="label" label="Sperrartikelpreisspalte" dense
                            required></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platform.priority" label="Priorität" type="number" step="1" dense
                            required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platform.description" label="Beschreibung" dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-switch v-model="platform.issupplierpriceprofit" :false-value=0 :true-value=1>
                            <template v-slot:label>
                                <v-tooltip color="black" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">Preisgewinn der Lieferanten Großhändler
                                            anwenden</span>
                                    </template>
                                    Wenn es aktiviert ist dann wird der Preisgewinn der Lieferanten Großhändler den Preisgewinn des Mindestpreises der Platform ersetzen.
                                </v-tooltip>
                            </template>
                        </v-switch>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-switch v-model="platform.checksupplier" :false-value=0 :true-value=1>
                            <template v-slot:label>
                                <v-tooltip color="black" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">Händler prüfen</span>
                                    </template>
                                    Es prüft die Händler der Plattform ob sie in der Händlerliste des Preisscanner
                                    existieren.
                                    Beim fehlenden Händler wird eine Benachrichtigung per E-Mail gesendet.
                                </v-tooltip>
                            </template>
                        </v-switch>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-switch v-model="platform.isreplacewithdummy" :false-value=0 :true-value=1>
                            <template v-slot:label>
                                <v-tooltip color="black" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">Scheinangebote hinzufügen</span>
                                    </template>
                                    Fügt zusätzlich die Scheinangebote hinzu. 
                                </v-tooltip>
                            </template>
                        </v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-switch v-model="platform.isftp" label="FTP" :false-value=0 :true-value=1></v-switch>
                </v-row>
                <v-row v-if="platform.isftp">
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platform.ftpAdress" :rules="nameRules" label="FTP-Adresse" dense
                            required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platform.ftpUser" :rules="nameRules" label="FTP-Benutzername" dense
                            required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platform.ftpPassword" :rules="nameRules" label="FTP-Passwort" dense
                            required></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="platform.isftp">
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platform.ftppath" :rules="nameRules" label="FTP-Verzeichnis" dense
                            required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platform.ftpfilename" :rules="nameRules" label="FTP-Dateiname" dense
                            required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field v-model="platform.fileCopynumber" :rules="nameRules"
                            label="Anzahl der Dateikopien" dense required></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-switch v-model="platform.LastAction" color="success" :false-value=0 :true-value=1>
                            <template v-slot:label>
                                <v-tooltip color="black" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">
                                            {{ platform.LastAction > 0 ? "Plattform aktiviert" : "Plattform deaktiviert"
                                            }}
                                        </span>
                                    </template>
                                    Aktiviert bzw. deaktiviert die Plattform
                                </v-tooltip>
                            </template>
                        </v-switch>
                    </v-col>
                </v-row>
                <v-row justify="space-between">

                    <v-btn rounded color="error" @click="back">
                        Abbrechen
                    </v-btn>

                    <slot name="submit" :submit="submit"
                        v-if="platform.name && platform.matchcode && platform.supplierPath">
                        <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                    </slot>
                </v-row>
            </v-form>
        </div>
    </v-app>
</template>
<script>
import axios from 'axios';

export default {
    props: {
        value: Array,

        url: {
            type: String,
            default: '/admin/platforms'
        },

        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },

        headers: {
            default: null
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-success"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },
        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },
    showPassword: false,

    data: () => ({
        nameRules: [v => !!v || 'Erforderlich',],
        countryoptions: [],
        currencyoptions: [],
        artikelcolumns: [],
        directories: [],
        enableSubmit: false,
        visibility: 'password',
        platform: {
            name: null,
            matchcode: '',
            debitorNr: null,
            supplierPath: null,
            // filename: null,
            checksupplier: 0,
            isftp: false,
            ftpAdress: null,
            ftpUser: null,
            ftpPassword: null,
            ftppath: null,
            ftpfilename: null,
            fileCopynumber: 0,
            country: null,
            currency: 'EUR',
            blockingartikelpricecolumn: null,
            priority: 0,
            issupplierpriceprofit: 1,
            isreplacewithdummy: 0,
            description: '',
            LastAction: 1
        }
    }),

    created() {
        this.getArtikelColumns();
        this.getDirectories('/admin/supplierstocktemplatedirectories/0');
        this.getCountries();
        this.getCurrencies();
    },

    methods: {
        showPassword() {
            this.visibility = 'text';
        },
        hidePassword() {
            this.visibility = 'password';
        },
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/platforms";
        },
        refreshSupplierDirectories() {
            this.getDirectories('/admin/supplierstocktemplatedirectories/1');
        },
        submit() {
            const _this = this;
            axios.post(this.url,
                _this.platform
            ).then(response => {
                console.log(response);
                this.showMsgBoxOne(response);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        },
        showMsgBoxOne(callback) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    window.location = location.protocol + "//" + location.hostname + "/admin/platforms";
                })
                .catch(err => {
                    // An error occurred
                })
        },
        getArtikelColumns: function () {
            axios.get('/admin/getallartikelcolumns')
                .then(function (response) {
                    this.artikelcolumns = response.data;
                }.bind(this));
        },
        getCountries: function () {
            axios.get('/admin/getshippingcountries')
                .then(function (response) {
                    this.countryoptions = response.data;
                }.bind(this));
        },
        getCurrencies: function () {
            axios.get('/admin/getcurrenciesisocodes')
                .then(function (response) {
                    this.currencyoptions = response.data;
                }.bind(this));
        },
        getDirectories: function (url) {
            axios.get(url)
                .then(function (response) {
                    this.directories = response.data;
                }.bind(this));
        },
        changeDirectory: function (value) {
            console.log(value);
        },
        changeVisibility: function () {
            this.showPassword = !this.showPassword
            if (this.showPassword) {
                this.visibility = 'text'
            } else {
                this.visibility = 'password'
            }
        },
    },
    watch: {

    },
    computed: {

    },
};
</script>
