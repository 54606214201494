<template>
  <v-app>
    <v-row>
      <v-data-table :headers="headers" :items="platformownoffersexports" :items-per-page="itemsPerPage"
        class="platformownoffersexport-table" :server-items-length="totalPlatformownoffers" :loading="loading"
        :options.sync="options" locale="de" :footer-props="footerProps" multi-sort loading-text="Wird geladen..."
        fixed-header height="200vh">
        <template v-slot:header.platformname="{ header }">
          {{ header.text }}
          <v-menu v-model="platformfilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="platform ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-autocomplete v-model="platform" :items="platforms" item-text="name" item-value="id"
                label="Auswählen" persistent-hint single-line clearable autofocus
                @click:clear="platform = '', clearFilter()" @keydown.enter.prevent="filter"></v-autocomplete>
              <v-btn text @click="platformfilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="platform = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
                <template v-slot:header.platformtemplatename="{ header }">
          {{ header.text }}
          <v-menu v-model="platformtemplatefilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="platformtemplate ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-autocomplete v-model="platformtemplate" :items="platformtemplates" item-text="name" item-value="id"
                label="Auswählen" persistent-hint single-line clearable autofocus
                @click:clear="platformtemplate = '', clearFilter()" @keydown.enter.prevent="filter"></v-autocomplete>
              <v-btn text @click="platformtemplatefilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="platformtemplate = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:header.ARAINR="{ header }">
          {{ header.text }}
          <v-menu v-model="arainrfilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="arainr ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field v-model="arainr" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                :autofocus="true" @keydown.enter.prevent="filter"></v-text-field>
              <v-btn text @click="arainrfilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="arainr = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:header.AREAN="{ header }">
          {{ header.text }}
          <v-menu v-model="eanfilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="ean ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field v-model="ean" class="pa-4" type="text" label="Geben Sie den Suchbegriff ein"
                :autofocus="true" @keydown.enter.prevent="filter"></v-text-field>
              <v-btn text @click="eanfilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="ean = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:header.ARWG="{ header }">
          {{ header.text }}
          <v-menu v-model="arwgfilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="arwg ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field v-model="arwg" class="pa-4" type="numeric" label="Geben Sie den Suchbegriff ein"
                hint="Erlaubte Vorzeichen =, >, >=, <, <= " persistent-hint :autofocus="true"
                @keydown.enter.prevent="filter">
              </v-text-field>
              <v-btn text @click="arwgfilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="arwg = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:header.ownoffer="{ header }">
          {{ header.text }}
          <v-menu v-model="ownofferfilter" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon small :color="ownoffer ? 'primary' : ''">
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field v-model="ownoffer" class="pa-4" type="numeric" label="Geben Sie den Suchbegriff ein"
                hint="Erlaubte Vorzeichen =, >, >=, <, <= " persistent-hint :autofocus="true"
                @keydown.enter.prevent="filter">
              </v-text-field>
              <v-btn text @click="ownofferfilter = false">
                <v-icon dark>
                  mdi-close
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn @click="ownoffer = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                      <v-icon dark>
                        mdi-filter-off
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>Filter aufheben</span>
              </v-tooltip>
              <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                <v-icon dark>
                  mdi-database-search
                </v-icon>
              </v-btn>
            </div>
          </v-menu>
        </template>
        <template v-slot:item.ownprice="{ item }">
            {{ convertDoubleToCurrency(item.ownprice) }}
        </template>
        <template v-slot:item.ownofferstock="{ item }">
          <v-chip color="cyan" text-color="white" small>
            {{ item.ownofferstock }}
          </v-chip>
        </template>
        <template v-slot:item.ownoffer="{ item }">
          <v-chip :color="getColor(item.ispriceexceeded)" text-color="white" small>
            {{ convertDoubleToCurrency(item.ownoffer) }}
          </v-chip>
        </template>
        <template v-slot:item.oneunitownoffer="{ item }">
          <v-chip color="orange" text-color="white" small>
            {{ convertDoubleToCurrency(item.oneunitownoffer) }}
          </v-chip>
        </template>
        <template v-slot:item.ARGW="{ item }">
          {{ convertDoubleToCurrency(item.ARGW) }}
        </template>
      </v-data-table>
    </v-row>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    value: Array,
    editdata: null,
    url: {
      type: String,
      default: '/admin/platformownoffersexports'
    },
    location_url: {
      type: String,
      default: '/admin/platformownoffersexports/'
    },
    callback: {
      type: Function,
      default: () => ({})
    },
    catch: {
      type: Function,
      default: () => ({})
    },
    finally: {
      type: Function,
      default: () => ({})
    },

    submitBtnText: {
      type: String,
      default: "Speichern"
    },
    buttonClass: {
      type: String,
      default: "btn btn-primary"
    },
    inputClass: {
      type: String,
      default: "form-control-file"
    },
    validation: {
      type: Boolean,
      default: true,
    },

    tableSelectClass: {
      type: String,
      default: 'form-control'
    },
  },

  data: () => ({
    platforms: [],
    platformtemplates: [],
    platformownoffersexports: [],
    totalPlatformownoffers: 0,
    itemsPerPage: 100,
    loading: true,
    conditions: [],
    filters: [],
    options: {
      page: 1,
      itemsPerPage: 100,
      sortDesc: [false],
      mustSort: false,
      multiSort: false,
      sort: '',
      filters: null
    },
    platform: '',
    platformtemplate: '',
    arainr: '',
    ean: '',
    arwg: '',
    ownoffer: '',
    platformfilter: false,
    platformtemplatefilter: false,
    arainrfilter: false,
    eanfilter: false,
    arwgfilter: false,
    ownofferfilter:false,

    headers: [
      { text: 'Plattform', value: 'platformname' },
      { text: 'Plattformvorlage', value: 'platformtemplatename' },
      { text: 'ARAINR', value: 'ARAINR' },
      { text: 'AREAN', value: 'AREAN' },
      { text: 'ARWG', align: 'left', width: "1%", value: 'ARWG' },
      { text: 'ARAFPK', align: 'left', width: "1%", value: 'ARAFPK' },
      { text: 'Lieferantenbestand', align: 'left', width: "1%", value: 'ownstock' }, 
      { text: 'Lieferantenpreis', value: 'ownprice' },
      { text: 'Angebostsbestand', value: 'ownofferstock' },
      { text: 'Angebot', value: 'ownoffer' }, 
      { text: 'Erstellt am', value: 'createdAt' }
    ],
    footerProps: {
      'items-per-page-options': [100, 500, 1000, -1],
      'show-current-page': true,
      'show-first-last-page': true,
      disableItemsPerPage: false,
      itemsPerPageText: 'Einträge pro Seite',
      itemsPerPageAllText: 'Alle',
    }
  }),
  created() {
    this.filters = new Array();
    this.getPlatformNames();
    this.getPlatformtemplateNames();
  },
  mounted() {
    window.platformownoffersexports = this;
  },
  methods: {
    async clearFilter() {
      this.filter();
    },
    convertDoubleToCurrency(value) {
      return value === null ? '' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
    },
    async getPlatformNames() {
      axios.get('/admin/getallplatforms')
        .then(function (response) {
          this.platforms = response.data;
        }.bind(this));
    },

    async getPlatformtemplateNames() {
      axios.get('/admin/getplatformtemplatesnames')
        .then(function (response) {
          this.platformtemplates = response.data;
        }.bind(this));
    },

    async getPlatformOwnOffersExports() {
      this.loading = true
      this.options.sort = this.options.sortBy[0];
      axios.get('/admin/getallplatformownoffersexports/', { params: this.options })
        .then(function (response) {
          this.platformownoffersexports = response.data.platformownoffersexports.data;
          this.totalPlatformownoffers = response.data.platformownoffersexports.total
          this.itemsPerPage = response.data.per_page
          this.loading = false
        }.bind(this));
    },

    getColor(value) {
      if (value > 0) return "red"
      else return "green"
    },
    
    showMsgBoxOne(callback, change) {
      this.$bvModal.msgBoxOk(callback.data)
        .then(value => {
          if (change) {
            window.location = location.protocol + "//" + location.hostname + this.location_url;
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      //this.nameState = valid
      return valid
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },

    filter() {
      this.filters.length = 0;
      if (this.arainr) {
        this.filters.push(new Array('ARAINR', 'like', this.arainr));
      }
      if (this.ean) {
        this.filters.push(new Array('AREAN', '=', this.ean));
      }
      if (this.platform) {
        this.filters.push(new Array('platform_id', '=', this.platform));
      }

      if (this.platformtemplate) {
        this.filters.push(new Array('platformtemplate_id', '=', this.platformtemplate));
      }

      if (this.arwg) {
        let wg = null;
        let operator = null;
        try {
          wg = parseInt(this.arwg.replace(/^[^0-9]+/, ''), 10);
          operator = this.arwg.replace(wg, "").trim();
          operator = operator.length === 0 ? "=" : operator;
          this.filters.push(new Array('ARWG', operator, wg));
        } catch (error) {

        } finally {
          wg = null;
          operator = null;
        }
      }

    if (this.ownoffer) {
        let oo = null;
        let operator = null;
        try {
          oo = parseInt(this.ownoffer.replace(/^[^0-9]+/, ''), 10);
          operator = this.ownoffer.replace(oo, "").trim();
          operator = operator.length === 0 ? "=" : operator;
          this.filters.push(new Array('ownoffer', operator, oo));
        } catch (error) {

        } finally {
          oo = null;
          operator = null;
        }
      }
      this.options.filters = this.filters;
      this.getPlatformOwnOffersExports();
    }
  },
  watch: {
    options(newValue, oldValue) {
      let cont = false
      if (oldValue.page !== newValue.page) cont = true
      if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
      if (oldValue.sortBy !== newValue.sortBy) cont = true
      if (oldValue.sortDesc !== newValue.sortDesc) cont = true
      if (cont) {
        this.getPlatformOwnOffersExports();
      }
    },
  },
};
</script>
