<template>
    <div class="vue-csv-uploader">
        <div class="form">
            <div class="vue-csv-uploader-part-one">
                <b-form-group role="form-group">
                    <label for="input-ARAINR">ARAINR:</label>
                    <b-form-input
                    id="input-ARAINR"
                    type="text"
                    v-model="ARAINR"
                    aria-describedby="input-ARAINR-help input-ARAINR-feedback"
                    placeholder="ARAINR"
                    trim
                     min="1"
                    readonly
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ARAINR-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-ARHWG">ARHWG:</label>
                    <b-form-input
                    id="input-ARHWG"
                    type="text"
                    v-model="ARHWG"
                    aria-describedby="input-ARHWG-help input-ARHWG-feedback"
                    placeholder="ARHWG"
                    trim
                    min="2"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ARHWG-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-ARWG">ARWG:</label>
                     <b-form-input
                    id="input-ARWG"
                    type="text"
                    v-model="ARWG"
                    aria-describedby="input-ARWG-help input-ARWG-feedback"
                    placeholder="ARWG"
                    trim
                    min="2"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ARWG-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-ARHE">ARHE:</label>
                     <b-form-input
                    id="input-ARHE"
                    type="text"
                    v-model="ARHE"
                    aria-describedby="input-ARHE-help input-ARHE-feedback"
                    placeholder="ARHE"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ARHE-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-AREAN">AREAN:</label>
                     <b-form-input
                    id="input-AREAN"
                    type="text"
                    v-model="AREAN"
                    aria-describedby="input-AREAN-help input-AREAN-feedback"
                    placeholder="AREAN"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-AREAN-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-ARHAN">ARHAN:</label>
                     <b-form-input
                    id="input-ARHAN"
                    type="text"
                    v-model="ARHAN"
                    aria-describedby="input-ARHAN-help input-ARHAN-feedback"
                    placeholder="ARHAN"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ARHAN-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-ARAFPK">ARAFPK:</label>
                     <b-form-input
                    id="input-ARAFPK"
                    type="text"
                    v-model="ARAFPK"
                    aria-describedby="input-ARAFPK-help input-ARAFPK-feedback"
                    placeholder="ARAFPK"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ARAFPK-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-ARALB">ARALB:</label>
                     <b-form-input
                    id="input-ARALB"
                    type="text"
                    v-model="ARALB"
                    aria-describedby="input-ARALB-help input-ARALB-feedback"
                    placeholder="ARALB"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ARALB-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-ARHKE">ARHKE:</label>
                     <b-form-input
                    id="input-ARHKE"
                    type="text"
                    v-model="ARHKE"
                    aria-describedby="input-ARHKE-help input-ARHKE-feedback"
                    placeholder="ARHKE"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ARHKE-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-ARDEL">ARDEL:</label>
                     <b-form-input
                    id="input-ARDEL"
                    type="text"
                    v-model="ARDEL"
                    aria-describedby="input-ARDEL-help input-ARDEL-feedback"
                    placeholder="ARDEL"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ARDEL-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-ARTIRENR">ARTIRENR:</label>
                     <b-form-input
                    id="input-ARTIRENR"
                    type="text"
                    v-model="ARTIRENR"
                    aria-describedby="input-ARTIRENR-help input-ARTIRENR-feedback"
                    placeholder="ARTIRENR"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ARTIRENR-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-ART24ID">ART24ID:</label>
                     <b-form-input
                    id="input-ART24ID"
                    type="text"
                    v-model="ART24ID"
                    aria-describedby="input-ART24ID-help input-ART24ID-feedback"
                    placeholder="ART24ID"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ART24ID-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-ART24MB">ART24MB:</label>
                     <b-form-input
                    id="input-ART24MB"
                    type="text"
                    v-model="ART24MB"
                    aria-describedby="input-ART24MB-help input-ART24MB-feedback"
                    placeholder="ART24MB"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-ART24MB-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-Mindestpreis">Mindestpreis:</label>
                     <b-form-input
                    id="input-Mindestpreis"
                    type="text"
                    v-model="Mindestpreis"
                    aria-describedby="input-Mindestpreis-help input-Mindestpreis-feedback"
                    placeholder="Mindestpreis"
                    trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-Mindestpreis-feedback">
                     Erforderlich
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-PlatformCheckBestPreisNr">Bestpreisspalte der Platform:</label>
                     <b-form-input
                    id="input-PlatformCheckBestPreisNr"
                    type="text"
                    v-model="PlatformCheckBestPreisNr"
                    aria-describedby="input-PlatformCheckBestPreisNr-help input-PlatformCheckBestPreisNr-feedback"
                    placeholder="PlatformCheckBestPreisNr"
                    trim
                    ></b-form-input>
                </b-form-group>
                <b-form-group role="form-group">
                    <label for="input-PlatformVerkaufStop">Platformverkauf:</label>
                    <b-form-checkbox
                        id="checkbox-PlatformVerkaufStop"
                        v-model="PlatformVerkaufStop"
                        name="checkbox-PlatformVerkaufStop"
                        value="accepted"
                        >
                    Platformverkauf stoppen
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group>
                    <b-button href='#' variant='secondary' v-on:click="back">Zurück</b-button>
                    <slot name="submit" :submit="submit">
                        <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                    </slot>
                </b-form-group>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default:  '/admin/supplierstocktemplate/'
            },
            mapFields: {
                //required: true
                type: Array,
                default: () => {
                    return ['Artikelnummer', 'Preis', 'Menge', 'Reifen_Pro_Paket', 'EAN']
                }
            },
            pcart04mapFields: {
                //required: true
                type: Array,
                default: () => {
                       return [ 'ARAINR','ARHWG','ARWG','ARPLB1','ARPLB2','ARHE','AREAN','AREAN2','AREAN3','AREAN4','ARHAN','ARRBRT','ARQUER','ARZOGR','ARLOAD','ARINDK','ARDOT','ARNG','ARKV','ARDBW','ARLKL','ARCEK','AREURI',
                    'ARMSKE','ARSNOFL','ARFARB','ARRABR','ARRAGR','AREPTI','ARLOZ2','ARLOKR','ARLOK2','ARNAD1','ARAFPK','ARALB','AREISLO','AREPDB','ARVK1','ARVK2','ARVK3','ARVK4','ARVK5','ARVK6',
                    'ARVK7','ARVK8','ARVK9','ARVK10','ARVK11','ARVK12','ARVK13','ARVK14','ARVK15','ARVK16','ARVK17','ARVK18','ARHKE','ARDEL','ARTIRENR','ART24ID','ART24MB']
                }
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            parseConfig: {
                type: Object,
                default() {
                    return {};
                }
            },
            headers: {
                default: null
            },
            loadBtnText: {
                type: String,
                default: "Laden"
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            autoMatchFields: {
                type: Boolean,
                default: false
            },
            autoMatchIgnoreCase: {
                type: Boolean,
                default: false
            },
            tableClass: {
                type: String,
                default: "table"
            },
            checkboxClass: {
                type: String,
                default: "form-check-input"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },
            fileMimeTypes: {
                type: Array,
                default: () => {
                    return ['text/csv','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'text/x-csv', 'application/vnd.ms-excel', 'text/plain'];
                }
            },
            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
            canIgnore: {
               type: Boolean,
               default: false,
            }
        },

        data: () => ({
            form: {
                  csv: null,
                },
                ARAINR: 0,
                ARHWG: '',
                ARWG: '',
                ARHE: '',
                AREAN: '',
                ARHAN: '',
                ARAFPK: '',
                ARALB : '',
                ARHKE : '',
                ARDEL : '',
                ARTIRENR: '',
                ART24ID: '',
                ART24MB: '',
                Mindestpreis: '',
                Mindestpreisdate: '',
                PlatformVerkaufspreis: '',
                PlatformVerkaufsdatum: '',
                PlatformVerkaufslastbestpreis: '',
                PlatformVerkaufslastbeststoppreis: '',
                PlatformCheckBestPreisNr: '',
                MindestpreisschritteProStunden: '',
                Mindestpreisschrittpreis: '',
                PlatformVerkaufStop: '',
                description: '',
        }),

        created() {
            this.ARAINR = this.editdata.ARAINR;
            this.ARHWG = this.editdata.ARHWG;
            this.ARWG = this.editdata.ARWG;
            this.ARHE = this.editdata.ARHE;
            this.AREAN = this.editdata.AREAN;
            this.ARHAN = this.editdata.ARHAN;
            this.ARAFPK = this.editdata.ARAFPK;
            this.ARALB = this.editdata.ARALB;
            this.ARHKE = this.editdata.ARHKE;
            this.ARDEL = this.editdata.ARDEL;
            this.ARTIRENR = this.editdata.ARTIRENR;
            this.ART24ID = this.editdata.ART24ID;
            this.ART24MB = this.editdata.ART24MB;
            this.Mindestpreis = this.editdata.Mindestpreis;
            this.Mindestpreisdate = this.editdata.Mindestpreisdate;
            this.PlatformVerkaufspreis = this.editdata.PlatformVerkaufspreis;
            this.PlatformVerkaufsdatum = this.editdata.PlatformVerkaufsdatum;
            this.PlatformVerkaufslastbestpreis = this.editdata.PlatformVerkaufslastbestpreis;
            this.PlatformVerkaufslastbeststoppreis = this.editdata.PlatformVerkaufslastbeststoppreis;
            this.PlatformCheckBestPreisNr = this.editdata.PlatformCheckBestPreisNr;
            this.MindestpreisschritteProStunden = this.editdata.MindestpreisschritteProStunden;
            this.Mindestpreisschrittpreis = this.editdata.Mindestpreisschrittpreis;
            this.PlatformVerkaufStop = this.editdata.PlatformVerkaufStop;
            this.description = this.editdata.description;
        },

        methods: {
            back: function(){
                window.location = location.protocol + "//" + location.hostname + "/admin/masterartikel/";
            },
            submit() {
                const _this = this;
                axios.put(this.url + this.id,
                    stocktemplate,
                ).then(response => {
                   console.log(response);
                   this.showMsgBoxOne(response);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },
        },
        computed: {


        },
    };
</script>
