<template>
    <v-app>
        <v-form>
            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field v-model="platformName" label="Platformname" dense readonly></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field v-model="platformMatchcode" label="Matchcode" dense readonly></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field v-model="debitorNr" label="DebitorNr" dense readonly></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field v-model="platformSupplierPath" label="Pfad der Plattform" dense readonly></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field v-model="supplierNumber" label="Lieferantennummer" dense readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field v-model="ptemplate.name" :rules="templatenameRules" hint="Platformvorlage"
                        label="Name der Platformvorlage" required></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-autocomplete v-model="ptemplate.filepath" :items="directories" label="Pfad der Plattformdatei"
                        persistent-hint required>
                        <template v-slot:prepend>
                            <v-tooltip text="Tooltip">
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                    icon
                                    color="green"
                                    @click="refreshSupplierDirectories"
                                    >
                                    <v-icon v-bind="props">mdi-cached</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" v-if="ptemplate.isnotemplate == 0">
                    <v-text-field v-model="ptemplate.filename" :rules="filenameRules" hint="Dateiname der Plattform"
                        label="Name der Plattformdatei" required></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <v-select v-model="ptemplate.country" :items="countryoptions"
                        :rules="[v => !!v || 'Land ist erforderlich!']" item-value="code" item-text="country" label="Land"
                        required></v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-autocomplete v-model="ptemplate.artikeltype" :items="platformtemplateartikeltypes" item-value="name"
                        item-text="name" label="Artikeltype" required></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                    <v-autocomplete v-model="ptemplate.importorder" :items="importorders"
                        label="Import-Reihenfolge"></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field v-model="ptemplate.import_timeout"
                        hint="Importdatei alle eingegebenen Minuten prüfen. Für nicht prüfen 0 eingeben"
                        label="Importdatei-Zeitüberschreitung (Minuten)"></v-text-field>
                </v-col>
                <v-col cols="12" md="2" v-if="ptemplate.isnotemplate == 0">
                    <v-btn tile color="success" @click.native="openMapping">
                        <v-icon>
                            mdi-file-cog
                        </v-icon>
                        Zuordnung
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <v-checkbox v-model="ptemplate.isnotemplate" hide-details class="shrink mr-2 mt-0"
                        label="Die Datei der Plattform nicht importieren" @change="checkIsnotemplate"></v-checkbox>
                </v-col>

            </v-row>
            <v-row v-if="ptemplate.isnotemplate && !ptemplate.isbestpricefromcomparedplatforms">
                <v-col cols="12" md="4">
                    <v-autocomplete v-model="ptemplate.offerfromplatform" :items="platforms" item-text="name"
                        item-value="id" label="Angebotspreise von der Plattform übernehmen" persistent-hint
                        prefix="Plattform:"></v-autocomplete>
                </v-col>
            </v-row>
            <v-row v-if="ptemplate.isnotemplate < 1 && !ptemplate.isbestpricefromcomparedplatforms">
                <v-col cols="12" md="6">
                    <b-form-checkbox id="checkbox-isdependent" v-model="ptemplate.isdependent" name="checkbox-isdependent"
                        value="1" unchecked-value="0">
                        Händlerpreise sind von der anderen Plattform abhängig
                    </b-form-checkbox>
                </v-col>

            </v-row>
            <v-row v-if="ptemplate.isdependent > 0 && !ptemplate.isbestpricefromcomparedplatforms">
                <v-col cols="12" md="4">
                    <v-autocomplete v-model="ptemplate.dependentplatform_id" :items="platforms" item-text="name"
                        item-value="id" label="Händlerpreise von der Plattform übernehmen" persistent-hint
                        prefix="Abhängig von der Plattform:"></v-autocomplete>
                </v-col>
            </v-row>
            <v-row v-if="ptemplate.isdependent == 0 && !ptemplate.is_comparedependownoffer" >
                <v-col cols="12" md="8" >
                    <v-checkbox v-model="ptemplate.isbestpricefromcomparedplatforms" hide-details class="shrink mr-2 mt-0"
                        label="Bestpreise der anderen Plattformen vergleichen" @change="checkIsbestpricefromcomparedplatforms"></v-checkbox>
                </v-col> 
                <v-col cols="12" md="8" style="padding-left: 50px;" v-if="ptemplate.isbestpricefromcomparedplatforms">
                    <v-checkbox 
                    v-model="ptemplate.is_compareifnobestprice" 
                    hide-details class="shrink mr-2 mt-0"
                    label="Bestpreisvergleich nur, wenn kein Bestpreis vorhanden. Bei keinem Bestpreis der ersten Plattform, dann wird den Bestpreis der nächsten Plattform übernohmen." 
                    @change="checkIsCompareifnobestprice">
                    </v-checkbox>
                </v-col>
            </v-row>
            <v-row  v-if="ptemplate.isbestpricefromcomparedplatforms && ptemplate.is_compareifnobestprice">
                <v-row style="padding-left: 50px; margin-bottom: 30px;">
                    <v-col cols="12" md="8">
                        <v-select
                        v-model="ptemplate.mainbestpriceplatform_id"
                        :items="platforms"
                        item-text="name"
                        item-value="id"
                        label="Die Hauptplattform des Bestpreisvergleiches"
                        chips
                        item-color="primary"
                        hint="Die Hauptplattform wird erst geprüft ob es ein Bestpreis vorhanden."
                        persistent-hint
                        ></v-select>
                    </v-col>  
                </v-row>
            </v-row>
            <v-row  v-if="ptemplate.isbestpricefromcomparedplatforms">
                <v-row style="padding-left: 50px; margin-bottom: 30px;">
                    <v-col cols="12" md="8">
                        <v-select
                        v-model="ptemplate.bestpricecompareplatforms"
                        :items="platforms"
                        item-text="name"
                        item-value="id"
                        label="Plattformen des Bestpreisvergleichs"
                        multiple
                        chips
                        :counter="ptemplate.is_compareifnobestprice > 0 ? 1 : 25"
                        deletable-chips
                        :hint="bestpricecompareplatformsHint"
                        persistent-hint
                        @change="checkBestpricecompareplatforms"
                        ></v-select>
                    </v-col>  
                </v-row>
            </v-row>
            <v-row v-if="ptemplate.isnotemplate < 1 && !ptemplate.isbestpricefromcomparedplatforms">
                <v-col cols="12" md="8" >
                    <v-checkbox v-model="ptemplate.is_comparedependownoffer" hide-details class="shrink mr-2 mt-0"
                        label="Die Angebote einer anderen Plattform mit den Bestpreisen vergleichen" @change="checkIsComparedependownoffer"></v-checkbox>
                </v-col> 
            </v-row>
            <v-row v-if="ptemplate.is_comparedependownoffer">
                <v-row style="margin-left: 20px;">
                    <v-col cols="6" md="4" >
                        <v-select
                        v-model="ptemplate.comparedependownoffer_platform_id"
                        :items="platforms"
                        item-text="name"
                        item-value="id"
                        label="Plattform der Angeboten"
                        chips
                        hint="Die Angebote und deren Bestände der selektierten Plattform werden mit den Bestpreise vergliechen und bei einem exakten Angebotpreis und exakten Bestand, wird der Bestpreis verworfen und der nächsten Bestpreis übernohmen."
                        persistent-hint
                        ></v-select>
                    </v-col>
                    <v-col  cols="6" md="4"> 
                        <!-- <v-select
                        v-model="ptemplate.comparedependownoffer_with_supplieroffer"
                        :items="bestpricesupplieroptions"
                        item-text="name"
                        item-value="id"
                        label="Vergleich mit dem Bestpreislieferanten"
                        chips
                        hint="Die Angebote und deren Bestände der selektierten Plattform werden mit dem Bestpreisplatz vergliechen und bei exakten Angebotpreis und Bestand dann wird der nächsten Bestpreis übernohmen."
                        persistent-hint
                        ></v-select> -->
                    </v-col>
                </v-row>
            </v-row>
            <v-row>
                <v-col cols="6" md="3" >
                    <v-checkbox v-model="ptemplate.ispickuppriceactive" hide-details class="shrink mr-2 mt-0"
                        label="Abholpreise berechnen"></v-checkbox>
                </v-col> 
            </v-row>
            <v-row v-if="ptemplate.ispickuppriceactive">
                <v-col cols="6" md="3" >
                    <v-text-field 
                    v-model="ptemplate.pickuppriceblockedartikelshippingmarkdown"
                    label="Preisabschlag der Sperrartikelfracht für die Abholpreisberechnung."
                    prefix="%"
                    type="number"
                    step="0.1"
                    min= 0
                    >
                    </v-text-field>
                </v-col> 
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <v-text-field v-model="ptemplate.description" label="Beschreibung"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <b-form-group>
                    <b-button href='#' variant='secondary' v-on:click="back">Zurück</b-button>
                    <slot name="submit" :submit="submit" v-if="ptemplate.name">
                        <input type="submit" :class="buttonClass" @click.prevent="submit" :value="submitBtnText">
                    </slot>
                </b-form-group>
            </v-row>
        </v-form>
    </v-app>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/platformtemplate'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
        parseConfig: {
            type: Object,
            default() {
                return {};
            }
        },
        headers: {
            default: null
        },
        loadBtnText: {
            type: String,
            default: "Laden"
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        autoMatchFields: {
            type: Boolean,
            default: false
        },
        autoMatchIgnoreCase: {
            type: Boolean,
            default: false
        },
        tableClass: {
            type: String,
            default: "table"
        },
        checkboxClass: {
            type: String,
            default: "form-check-input"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },
        fileMimeTypes: {
            type: Array,
            default: () => {
                return ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'text/x-csv', 'application/vnd.ms-excel', 'text/plain'];
            }
        },
        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
        canIgnore: {
            type: Boolean,
            default: false,
        }
    },

    data: () => ({
        form: {
            csv: null,
        },
        templatenameRules: [v => !!v || 'Vorlagename ist erforderlich',],
        filenameRules: [
            v => !!v || 'Dateiname ist erforderlich',
        ],
        map: {},
        hasHeaders: true,
        csv: null,
        sample: null,
        isValidFileMimeType: false,
        fileSelected: false,
        supplierfiles: [],
        enableSubmit: false,
        countryoptions: [],
        platformtemplateartikeltypes: [],
        platformName: '',
        platformMatchcode: '',
        debitorNr: '',
        platformSupplierPath: '',
        supplierNumber: '',
        templateName: '',
        country: null,
        directories: [],
        platforms: [],
        importorders: [],
        myValue: '',
        selectedtemplatefile: null,
        templatefile: null,
        bestpricecompareplatformsHint: "",
        bestpricesupplieroptions: [
            {id:1, name:"Bestpreislieferant 1"},
            {id:2, name:"Bestpreislieferant 2"},
            {id:2, name:"Bestpreislieferant 3"},
            {id:4, name:"Bestpreislieferant 4"},
            {id:5, name:"Bestpreislieferant 5"},
            {id:6, name:"Bestpreislieferant 6"},
            {id:7, name:"Bestpreislieferant 7"},
            {id:8, name:"Bestpreislieferant 8"},
            {id:9, name:"Bestpreislieferant 9"},
        ],

        ptemplate: {
            id: 0,
            platforms_id: null,
            name: null,
            country: null,
            artikeltype: null,
            filepath: null,
            filename: null,
            isnotemplate: 0,
            isbestpricefromcomparedplatforms:0,
            bestpricecompareplatforms: [],
            is_compareifnobestprice: 0,
            mainbestpriceplatform_id: -1,
            ispickuppriceactive: 0,
            pickuppriceblockedartikelshippingmarkdown: 0,
            offerfromplatform: -1,
            isdependent: 0,
            dependentplatform_id: -1,
            is_comparedependownoffer: 0,
            comparedependownoffer_platform_id: 0,
            comparedependownoffer_with_supplieroffer: 0,
            importorder: -1,
            import_timeout: 0,
            description: null,
        },

        supplier_countries: [{
            platforms_id: 0,
            platformName: '',
            supplierNumber: '',
            code: '',
            country: '',
            price: '',
            parsel: 0,
            surchargetype: 0,
            surcharge: 0.00,
            surchargestartdate: null,
            surchargestopdate: null,
            description: null
        }],
    }),

    created() {
        this.ptemplate = this.editdata;
        this.getPlatforms();
        this.getCountries();
        this.getDirectories('/admin/supplierstocktemplatedirectories/0');
        this.getPlatformTemplateArtikelTypes();
        this.supplier_countries = [];
        this.ptemplate.platforms_id = this.editdata.platforms_id;
        this.getPlatform();
        this.getSupplierCountries();
        this.checkIsCompareifnobestprice();
        console.log(this.ptemplate.bestpricecompareplatforms);
    },

    methods: {
        async clearSelectedTemplate() {
            const _this = this;
            _this.ptemplate.templatefile = null;
            _this.sample = null;
            _this.csv = null;
            _this.ptemplate.templateheader = null;
        },

        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/platforms/" + this.ptemplate.platforms_id + "/platformtemplates";
        },
        toast(toaster, append = false) {
            this.$bvToast.toast(`Bitte Felder auswählen`, {
                title: `Toaster ${toaster}`,
                toaster: toaster,
                solid: true,
                appendToast: append
            })
        },

        getPlatforms: function () {
            axios.get('/admin/getallplatforms')
                .then(function (response) {
                    this.platforms = response.data.filter(this.filterPlatform);
                    this.importorders = Object.keys(this.platforms).map(index => {
                        return ++index;
                    });
                    this.importorders.push(++this.importorders.length);
                }.bind(this));
        },

        filterPlatform: function(platform){
            return platform.id !== this.editdata.platforms_id;
        },
        getSupplierCountries: function () {
            axios.get('/admin/suppliershippingcountries/getshippingscountries/' + this.ptemplate.platforms_id)
                .then(function (response) {
                    this.supplier_countries = response.data;
                }.bind(this));
        },
        getPlatformTemplateArtikelTypes: function () {
            axios.get('/admin/getplatformtemplateartikeltypes')
                .then(function (response) {
                    this.platformtemplateartikeltypes = response.data;
                }.bind(this));
        },
        getCountries: function () {
            axios.get('/admin/getshippingcountries')
                .then(function (response) {
                    this.countryoptions = response.data;
                }.bind(this));
        },
        getDirectories: function (url) {
            axios.get(url)
                .then(function (response) {
                    this.directories = response.data;
                }.bind(this));
        },
        refreshSupplierDirectories() {
            this.getDirectories('/admin/supplierstocktemplatedirectories/1');
        },
        getPlatform: function () {
            axios.get('/admin/getplatform/' + this.ptemplate.platforms_id)
                .then(function (response) {
                    this.platformName = response.data.name;
                    this.debitorNr = response.data.debitorNr;
                    this.platformMatchcode = response.data.matchcode;
                    this.platformSupplierPath = response.data.supplierPath;
                }.bind(this));
        },

        submit() {
            const _this = this;
            axios.post(this.url,
                _this.ptemplate,
            ).then(response => {
                // _this.callback(response);
                console.log(response);
                _this.showMessage(response);
                // _this.showMsgBoxOne(response);
            }).catch(response => {
                console.log("Fehler");
                _this.showMessage(response);
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        },

        showMessage(callback)
        {
            console.log(callback);
            Swal.fire({
                    title: callback.data.status === 'success' ? 'MELDUNG' : 'WARNUNG',
                    text: callback.data.message,
                    icon: callback.data.status === 'success' ? 'success' :'warning',
                    confirmButtonText: 'Ok'
                    });
        },
        
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data.message)
                .then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + "/admin/supplierstocktemplate";
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },

        openMapping: function (event) {
            window.location = location.protocol + "//" + location.hostname + "/admin/platformtemplate/" + this.ptemplate.id + "/mapping";
        },

        checkIsnotemplate() {
            if (this.ptemplate.isnotemplate) {
                this.ptemplate.filename = "Keine";
            } else {
                this.ptemplate.filename = null;
            }
        },

        checkIsCompareifnobestprice() {
            // this.ptemplate.bestpricecompareplatforms = [];
            if(this.ptemplate.is_compareifnobestprice > 0){
                // this.ptemplate.mainbestpriceplatform_id = -1;
                this.bestpricecompareplatformsHint = "Bei keinem Bestpreis der Hauptplattform dann wird den nächsten Bestpreis der Vergleichsplattform übernohmen.";
            }
            else {
                this.bestpricecompareplatformsHint = "Die Bestpreise der selektierten Plattformen werden vergliechen und den günstigsten Bestpreis wird übernohmen.";
            }
        },

        checkBestpricecompareplatforms() {

            if(
                this.ptemplate.is_compareifnobestprice > 0 
                && this.ptemplate.bestpricecompareplatforms !== null 
                && this.ptemplate.bestpricecompareplatforms.length > 1
            )
            {
                this.ptemplate.bestpricecompareplatforms.splice(0,1);
                Swal.fire({
                    title: 'Warnung!',
                    text: 'Nur eine Plattform auswählen!',
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                    });
            }
        },

        checkIsbestpricefromcomparedplatforms() {
             if (this.ptemplate.isbestpricefromcomparedplatforms > 0) {
                this.ptemplate.isnotemplate = false;
                this.ptemplate.filename = "Keine";
                this.ptemplate.isdependent = 0;
                this.ptemplate.offerfromplatform = -1;
                this.ptemplate.dependentplatform_id = -1;
                this.ptemplate.is_comparedependownoffer = 0;
                this.ptemplate.comparedependownoffer_platform_id = 0;
                this.ptemplate.comparedependownoffer_with_supplieroffer = 0;
            } else {
                this.ptemplate.is_compareifnobestprice = 0;
                this.ptemplate.mainbestpriceplatform_id = -1;
                this.ptemplate.bestpricecompareplatforms = [];
            }
        },

        checkIsComparedependownoffer() {
            if(this.ptemplate.is_comparedependownoffer > 0)
            {
                this.ptemplate.isnotemplate = false;
                this.ptemplate.filename = "Keine";
                this.ptemplate.isdependent = 0;
                this.ptemplate.offerfromplatform = -1;
                this.ptemplate.dependentplatform_id = -1;
                this.ptemplate.isbestpricefromcomparedplatforms = 0;
                this.ptemplate.bestpricecompareplatforms = [];
            } else {
                this.ptemplate.comparedependownoffer_platform_id = 0;
                this.ptemplate.comparedependownoffer_with_supplieroffer = 0;
            }
        }
    },
    watch: {

    },
    computed: {
        showErrorMessage() {
            return this.fileSelected && !this.isValidFileMimeType;
        },
        nameState() {
            if (this.ptemplate.name == null)
                return false
            return this.ptemplate.name.length > 3 ? true : false
        },

        filenameState() {
            if (this.ptemplate.filename == null)
                return false
            return this.ptemplate.filename.length > 3 ? true : false
        },
        disabledNextButton() {
            return this.ptemplate.templatefile == null;
        },
    },
};
</script>
