<template>
    <div class="row">
        <ownoffersclientmodal-component @reloadtable="receiveReloadTableEmit" />
        <div class="table-responsive">
            <table class="table table-bordered table-striped ownoffersclient-table" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Kundenname</th>
                        <th>Versandart</th>
                        <th>Port</th>
                        <th>Dateiname</th>
                        <th>Plattform-FTP</th>
                        <th>Beschreibung</th>
                        <th>Zugewisen an</th>
                        <th>Zugeordnet an</th>
                        <th>Scheinangebote senden</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {

        props: {
            value: Array,
            url: {
                type: String,
                default: '/admin/ownoffersclients'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Kunden hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
                enableSubmit: false,
                ownoffersclient: {
                    id:0,
                    name : null,
                    sendtype : 'FTP',
                    ftp_host : null,
                    ftp_port : 21,
                    ftp_username : null,
                    ftp_password : null,
                    ftp_filepath : null,
                    ftp_filename : null,
                    description: null, 
                    is_platform: 0,
                    is_send_dummyoffers: 0,
                    LastAction : 1
                },
                typeoptions:[ {key: 'FTP', type: 'FTP'},{key: 'SFTP', type: 'SFTP'},{key: 'LOKAL', type: 'LOKAL'}],
        }),

        created() {

            this.getEditOwnOffersClientsData('/admin/getownoffersclientsdata');
        },
        mounted() {
        },
        methods: {
                async getEditOwnOffersClientsData(address) {
                 $(document).ready(function() {
                    var collapsedGroups = {};
                    var table = $('.ownoffersclient-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'name', name: 'name'},
                            {data: 'sendtype', name: 'sendtype'},
                            {data: 'ftp_port', name: 'Port' },
                            {data: 'ftp_filename', name: 'ftp_filename'},
                            { data: 'isplatform', name: 'isplatform' },
                            {data: 'description', name: 'Beschreibung'},
                            { data: 'related_platformname', name: 'Zugewisen an' },
                            { data: 'related_configname', name: 'Zugeordnet an' },
                            { data: 'issenddummyoffers', name: 'Scheinangebote senden'},
                            {data: 'status', name: 'status'},
                            {data: 'action', name: 'Aktionen'},
                        ],
                        //order: [[ 4, 'asc' ]],
                    });
                });
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                        $('.ownoffersclient-table').DataTable().ajax.reload();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
            getDeleteOwnOffersClient(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('Möchten Sie den Kunde wirklich löschen?', {
                title: 'Bitte bestätigen Sie',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },
        receiveReloadTableEmit() {
            $('.ownoffersclient-table').DataTable().ajax.reload();
        },

        },
        watch: {

        },
        computed: {
   
        },
    };
</script>
