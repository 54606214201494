<template>
    <v-app>
        <v-data-table :headers="headers" :items="platformsupplierignores" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}'
        }">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Ignorierte Lieferanten der Plattform {{ editdata.name }}
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on">
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container>
                                    <v-form ref="form">
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="editdata.name" label="Plattform"
                                                    readonly></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-autocomplete v-model="editedItem.supplier_id" :items="suppliers"
                                                    label="Lieferant*" item-text="supplierName" item-value="id"
                                                    :rules="[v => !!v || 'Pflichtfeld']"
                                                    @change="getSupplierStockTemplates"
                                                    required></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-autocomplete v-model="editedItem.supplierstocktemplate_id" :items="supplierstocktemplates"
                                                    label="Lieferantenvorlage" item-text="templateName" item-value="id"
                                                    :rules="[v => v > -1 || 'Pflichtfeld']" required></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-autocomplete v-model="editedItem.herstellernumber"
                                                    :items="herstellers" label="Hersteller" item-text="name"
                                                    item-value="number"></v-autocomplete>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field v-model="editedItem.ARWG_from" label="ARWG von"
                                                    type="number" min="0"></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field v-model="editedItem.ARWG_to" label="ARWG bis"
                                                    type="number" min="0"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" v-if="isMainSupplier">
                                                <v-text-field v-model="editedItem.supplier_stock_less"
                                                    label="Lieferantenbestand unter" type="number" min="0"
                                                    hint="Wenn der Lieferantenbestand weniger als eingegebene Mindestmenge ist dann wird es ignoriert. Es gilt nur für den Hauptlieferant Inter-Drive!"
                                                    persistent-hint>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field label="Beschreibung" v-model="editedItem.description"
                                                    hide-details="auto"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-switch v-model="editedItem.lastAction" label="Status"
                                                    color="green"></v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="primary" @click="save">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="800px">
                        <v-card>
                            <v-card-title class="text-h6 warning">Sind Sie sicher, dass Sie diesen ignorierten Lieferant
                                löschen
                                möchten?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                                <v-btn color="red darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.supplier_id="{ item }">
                {{ item.supplier.supplierName }}
            </template>
            <template v-slot:item.supplierstocktemplate_id="{ item }">
                {{ item.supplierstocktemplate == null ? "Alle Vorlagen" : item.supplierstocktemplate.templateName }}
            </template>
            <template v-slot:item.herstellernumber="{ item }">
                {{ item.herstellername }}
            </template>
            <template v-slot:item.supplier_stock_less="{ item }">
                {{ item.supplier_stock_less > 0 ? '< ' + item.supplier_stock_less : '' }}
            </template>
            <template v-slot:item.lastAction="{ item }">
                <v-switch v-model="item.lastAction" color="green" disabled label=""></v-switch>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)" color="red">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Löschen</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <template>
            <v-row>
                <v-col cols="3">
                    <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
                </v-col>
            </v-row>
        </template>
    </v-app>
</template>
<script>
export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/platformsupplierignores'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
    },

    data: vm => ({
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        dialogWarning: false,
        rules: {
            name: [val => (val || '').length > 0 || 'Erforderlich'],
            number: [val => val > 0 || `Erforderlich`],
        },
        headers: [
            { text: 'Id', align: 'start', sortable: true, value: 'id'},
            { text: 'Lieferant', value: 'supplier_id' },
            { text: 'Lieferantenvorlage', value: 'supplierstocktemplate_id' },
            { text: 'Hersteller', value: 'herstellernumber' },
            { text: 'ARWG von', value: 'ARWG_from' },
            { text: 'ARWG bis', value: 'ARWG_to' },
            { text: 'Lieferantenbestand unter', value: 'supplier_stock_less' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Status', value: 'lastAction' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
        success: false,
        allplatforms: [],
        herstellers: [],
        suppliers: [],
        supplierstocktemplates: [],
        platformsupplierignores: [],
        mainSupplier: null,
        editedItem: {
            id: 0,
            platform_id: vm.editdata.id,
            supplier_id: null,
            supplierstocktemplate_id: 0,
            herstellernumber: null,
            ARWG_from: null,
            ARWG_to: null,
            supplier_stock_less: 0,
            description: '',
            lastAction: 1
        },
        defaultItem: {
            id: 0,
            platform_id: vm.editdata.id,
            supplier_id: null,
            herstellernumber: null,
            ARWG_from: null,
            ARWG_to: null,
            supplier_stock_less: 0,
            description: '',
            lastAction: 1
        },
    }),
    created() {
        this.getHerstellers();
        this.getSuppliers();
        this.getPlatformSupplierIgnores();
    },
    computed: {
        formTitle() {
            return this.editedItem.id === 0 ? 'Lieferant zu ignorieren hinzufügen' : 'Ingorierten Lieferant bearbeiten'
        },

        isMainSupplier() {
            if (this.mainSupplier == null)
            {
                return false;
            }
            
            if (this.editedItem.supplier_id === this.mainSupplier.id)
            {
                return true;
            }
            this.editedItem.min_quantity = 0;
            return false;
        }
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        dialogWarning(val) {
            val || this.closeDialogWarning()
        },
    },

    methods: {
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/platforms";
        },

        onSubmit(result) {
            alert(`You selected ${result}`)
        },
        getHerstellers: function () {
            axios.get('/admin/getallhersteller')
                .then(function (response) {
                    this.herstellers = response.data;
                }.bind(this));
        },

        async getSuppliers() {
            axios.get('/admin/getsupplierswithstocktemplates')
                .then(function (response) {
                    this.suppliers = response.data;
                    this.mainSupplier = this.suppliers.find(obj => obj.isMainSupplier > 0);
                }.bind(this));
        },
        
        getSupplierStockTemplates(supplier_id) {
            this.supplierstocktemplates = this.suppliers.find((e) => e.id == this.editedItem.supplier_id);
            this.supplierstocktemplates = this.supplierstocktemplates.supplierstocktemplates;
            this.supplierstocktemplates.unshift({'id':0, 'templateName':"Alle Vorlagen", 'supplier_id': this.editedItem.supplier_id, 'is_test': 0, 'lastAction': 1 });
        },

        getPlatformSupplierIgnores: function () {
            axios.get('/admin/platform/' + this.editdata.id + '/getplatformsupplierignores')
                .then(function (response) {
                    this.platformsupplierignores = response.data;
                }.bind(this));
        },

        editItem(item) {
            this.editedItem = item;
            this.getSupplierStockTemplates();
            this.dialog = true
        },

        deleteItem(item) {
            this.editedItem = item;
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            axios.delete(this.url + '/' + this.editedItem.id)
                .then(response => {
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            this.closeDelete();
        },

        filterItem(item) {
            window.location = location.protocol + "//" + location.hostname + "/admin/platform/" + item.id + "/platformsupplierignores";
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },
        
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },

        closeDialogWarning() {
            this.dialogWarning = false
        },

        async save() {
            const { valid } = await this.$refs.form.validate()
            const _this = this;
            if (this.editedItem.id > 0) {
                axios.put(this.url + '/' + this.editedItem.id,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
            this.close()
        },

        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        this.getPlatformSupplierIgnores();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
    },
}
</script>