<template>
    <div class="vue-csv-uploader">
        <v-app>
            <div class="table-responsive">
                <v-data-table
                dense
                v-model="selectedItems"
                :headers="headers"
                :items="ownofferstasks"
                item-key="id"
                show-select
                class="elevation-1"
                sort-by="platformtemplate.platformname"
                group-by="platformtemplate.platformname"
                show-group-by
                :options="footerProps"
                >
                    <template
                    v-slot:top
                    > 
                        <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
                            <!-- <v-container
                            class="pa-6"
                            > -->
                            <v-row
                            class="pa-6"
                            >
                                <v-col
                                cols="12"
                                md="3"
                                >
                                    <v-btn
                                    dense
                                    type="submit"
                                    color="success"
                                    :disabled="selectedItems.length == 0"
                                    >
                                    Neue Angebote erzwingen
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-alert
                                dense
                                type="warning"
                                dismissible
                                v-if="selectedItems.length == 0"
                                >
                                Erst müssen die Plattformvorlagen ausgewählt werden!
                                </v-alert>
                            </v-row>
                            <!-- </v-container> -->
                        </v-form>
                    </template>
                     <template v-slot:item.is_force_createoffers="{ item }">
                        <v-switch
                        v-model="item.is_force_createoffers"
                        color="success"
                        hide-details
                        readonly
                        ></v-switch>
                    </template>
                </v-data-table>  
            </div>
        </v-app>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/forcecreateownoffers'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            validation: {
                type: Boolean,
                default: true,
            },
        },

        data: () => ({
            rules: {
                required: value => !!value || 'Erforderlich',
                counter: value => value.length <= 20 || 'Max 20 characters',
                arwg: value => {
                    return /^\d+$/.test(value) || 'Ungültig'
                },
                price: value => {
                   return /^[\+\-]?\d+(?:[\.,]\d{1,2})?$/.test(value) || 'Ungültig'
                },
            },
            filters: [],
                options: {
                page: 1,
                itemsPerPage: 50,
                sortDesc: [false],
                mustSort: false,
                multiSort: false,
                sort: '',
                filters: null
            },
            enableSubmit: false,
            warengruppeVon:'',
            warengruppeVonFilter: false,
            warengruppeBis:'',
            warengruppeBisFilter: false,
            arwg_von:null,
            arwg_bis:null,
            gewinn:null,
            valid: false,
            selectedItems: [],
            headers: [
            // {text: 'Id', value: 'id'},
            {text: 'Plattform', value: 'platformtemplate.platformname'},
            { text: 'Plattformvorlage', value: 'platformtemplate.name' },
            { text: 'Erzwingen', value: 'is_force_createoffers' },
            ],

            footerProps: {
            'items-per-page-options': [-1],
            'show-current-page': true,
            'show-first-last-page': true,
            itemsPerPage: -1,
            disableItemsPerPage: false,
            itemsPerPageText: 'Einträge pro Seite',
            itemsPerPageAllText: 'Alle',
            },
            ownofferstasks: [],
            postdata:{
                ownoffertasks_Ids:[]
            }
        }),

        created() {
            this.filters = new Array();
            this.getOwnofferstasks();
        },

        methods: {
            async clearFilter() {
                this.filter();
            },
            convertDoubleToCurrency(value) {
                return value === null ? '' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
            },
            getOwnofferstasks: function(){
                axios.get('/admin/getownofferstasks/', { params: this.options })
                .then(function (response) {
                    this.ownofferstasks = response.data;
                }.bind(this));
            },

            submitForm() {
                const _this = this;
                if(_this.selectedItems.length == 0)
                {
                    var callback = {
                        data: "Plattformvorlagen müssen ausgewählt werden!",
                        status: 400,
                        typ: 'warning'
                    }
                    _this.showMsgBoxOne(callback, false);
                    return;
                }
                _this.postdata.ownoffertasks_Ids =  _this.selectedItems.map(item => item.id);
                axios.post(_this.url,
                     _this.postdata,
                ).then(response => {
                   _this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.showMsgBoxOne(response, false);
                     _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },

            showMsgBoxOne(callback, change) {
                if(callback.status > 200){
                        this.$bvModal.msgBoxOk(callback.data, {
                        title: 'FEHLER',
                        headerBgVariant: 'danger',
                        headerTextVariant: 'light',
                        titleVariant: 'danger',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'danger',
                        size:'lg',
                        okVariant: 'warning'
                         });
                    return;
                }
                this.$bvModal.msgBoxOk(callback.data, {
                        title: 'MELDUNG',
                        headerBgVariant: 'success',
                        headerTextVariant: 'light',
                        titleVariant: 'success',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'success',
                        size:'lg',
                        okVariant: 'success'
                         })
                .then(value => {
                    if(change)
                    {
                       this.getOwnofferstasks();
                    }
                })
                .catch(err => {
                   
                })
            },

            showError(mp) {
                if(mp.prozent == null)
                 {
                     this.$bvModal.msgBoxOk('Prozent darf nicht leer sein!'+ mp, {
                        title: 'FEHLER',
                        headerBgVariant: 'danger',
                        headerTextVariant: 'light',
                        titleVariant: 'danger',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'danger',
                        size:'lg',
                        okVariant: 'warning'
                         });
                 }
            },

            validate () {
                this.$refs.form.validate()
            },

            filter() {
                this.filters.length = 0;

                if (this.platform) {
                    this.filters.push(new Array('platformtemplate_id', '=', this.platform));
                }
                if (this.warengruppeVon) {
                    this.filters.push(new Array('warengruppeVon', '=', this.warengruppeVon));
                }
                if (this.warengruppeBis) {
                    this.filters.push(new Array('warengruppeBis', '=', this.warengruppeBis));
                }
                this.options.filters = this.filters;
                this.getPlatformtemplateMindestpreise();
            }
        },
        watch: {

        },
        computed: {

        },
    };
</script>
