var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-row',[_c('v-data-table',{staticClass:"platformownoffersexport-table",attrs:{"headers":_vm.headers,"items":_vm.platformownoffersexports,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalPlatformownoffers,"loading":_vm.loading,"options":_vm.options,"locale":"de","footer-props":_vm.footerProps,"multi-sort":"","loading-text":"Wird geladen...","fixed-header":"","height":"200vh"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.platformname",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.platform ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.platformfilter),callback:function ($$v) {_vm.platformfilter=$$v},expression:"platformfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-autocomplete',{attrs:{"items":_vm.platforms,"item-text":"name","item-value":"id","label":"Auswählen","persistent-hint":"","single-line":"","clearable":"","autofocus":""},on:{"click:clear":function($event){_vm.platform = '', _vm.clearFilter()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.platform),callback:function ($$v) {_vm.platform=$$v},expression:"platform"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.platformfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.platform = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"header.platformtemplatename",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.platformtemplate ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.platformtemplatefilter),callback:function ($$v) {_vm.platformtemplatefilter=$$v},expression:"platformtemplatefilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-autocomplete',{attrs:{"items":_vm.platformtemplates,"item-text":"name","item-value":"id","label":"Auswählen","persistent-hint":"","single-line":"","clearable":"","autofocus":""},on:{"click:clear":function($event){_vm.platformtemplate = '', _vm.clearFilter()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.platformtemplate),callback:function ($$v) {_vm.platformtemplate=$$v},expression:"platformtemplate"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.platformtemplatefilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.platformtemplate = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"header.ARAINR",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arainr ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.arainrfilter),callback:function ($$v) {_vm.arainrfilter=$$v},expression:"arainrfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.arainr),callback:function ($$v) {_vm.arainr=$$v},expression:"arainr"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arainrfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arainr = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"header.AREAN",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.ean ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.eanfilter),callback:function ($$v) {_vm.eanfilter=$$v},expression:"eanfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Geben Sie den Suchbegriff ein","autofocus":true},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.ean),callback:function ($$v) {_vm.ean=$$v},expression:"ean"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.eanfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.ean = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"header.ARWG",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.arwg ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.arwgfilter),callback:function ($$v) {_vm.arwgfilter=$$v},expression:"arwgfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"numeric","label":"Geben Sie den Suchbegriff ein","hint":"Erlaubte Vorzeichen =, >, >=, <, <= ","persistent-hint":"","autofocus":true},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.arwg),callback:function ($$v) {_vm.arwg=$$v},expression:"arwg"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.arwgfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.arwg = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"header.ownoffer",fn:function(ref){
var header = ref.header;
return [_vm._v("\n        "+_vm._s(header.text)+"\n        "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.ownoffer ? 'primary' : ''}},[_vm._v("\n                mdi-filter\n              ")])],1)]}}],null,true),model:{value:(_vm.ownofferfilter),callback:function ($$v) {_vm.ownofferfilter=$$v},expression:"ownofferfilter"}},[_vm._v(" "),_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"numeric","label":"Geben Sie den Suchbegriff ein","hint":"Erlaubte Vorzeichen =, >, >=, <, <= ","persistent-hint":"","autofocus":true},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.filter($event)}},model:{value:(_vm.ownoffer),callback:function ($$v) {_vm.ownoffer=$$v},expression:"ownoffer"}}),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.ownofferfilter = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-close\n              ")])],1),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"red"},on:{"click":function($event){_vm.ownoffer = ''; _vm.clearFilter()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      mdi-filter-off\n                    ")])],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("Filter aufheben")])]),_vm._v(" "),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"green","fixed":"","right":""},on:{"click":_vm.filter}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                mdi-database-search\n              ")])],1)],1)])]}},{key:"item.ownprice",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.convertDoubleToCurrency(item.ownprice))+"\n      ")]}},{key:"item.ownofferstock",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"cyan","text-color":"white","small":""}},[_vm._v("\n          "+_vm._s(item.ownofferstock)+"\n        ")])]}},{key:"item.ownoffer",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.ispriceexceeded),"text-color":"white","small":""}},[_vm._v("\n          "+_vm._s(_vm.convertDoubleToCurrency(item.ownoffer))+"\n        ")])]}},{key:"item.oneunitownoffer",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"orange","text-color":"white","small":""}},[_vm._v("\n          "+_vm._s(_vm.convertDoubleToCurrency(item.oneunitownoffer))+"\n        ")])]}},{key:"item.ARGW",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.convertDoubleToCurrency(item.ARGW))+"\n      ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }