<template>
    <div class="row">
        <b-modal id="modal-prevent-closing" :header-bg-variant="headerBgVariant" :header-text-variant="headerTextVariant"
            ref="modal" :title="modalname" @show="resetModal" @hidden="resetModal" @ok="handleOk" ok-title-html="Speichern"
            cancel-title="Abbrechen" :ok-disabled="disableOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">

                <b-form-group label="Lagerbestand" label-for="supllierstockname-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Lagerbestand ist pflicht">
                    <b-form-input id="supllierstockname-input" v-model="editdata.templateName" required
                        disabled></b-form-input>
                </b-form-group>
                <b-form-group label="Hersteller" label-for="hersteller-input" label-cols="8" label-cols-lg="4">
                    <b-form-select id="hersteller-input" v-model="supplierstockpricemarkup.hersteller_number"
                        :options="herstellers" value-field="number" text-field="name"
                        disabled-field="notEnabled"></b-form-select>
                </b-form-group>
                <b-form-group label="ARWG-Von" label-for="arwg-from-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="ARWG-Von ist pflicht">
                    <b-form-input id="arwg-from-input" v-model="supplierstockpricemarkup.productgroup_from"
                        :state="productgroupFromState" type="number" min="0" value="0" step="1" pattern="^(?:[0-9]\d*|\d)$"
                        required></b-form-input>
                </b-form-group>
                <b-form-group label="ARWG-bis" label-for="arwg-bis-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="ARWG-bis ist pflicht">
                    <b-form-input id="arwg-bis-input" v-model="supplierstockpricemarkup.productgroup_to"
                        :state="productgroupToState" type="number" min="0" value="0" step="1"
                        pattern="[0-9]+([\\,|\\.][0-9]+)?" required></b-form-input>
                </b-form-group>
                <b-form-group label="Zoll-Von" label-for="inch-from-input" label-cols="8" label-cols-lg="4">
                    <b-form-input id="inch-from-input" v-model="supplierstockpricemarkup.inch_from" type="number" min="0"
                        value="0" step="1" pattern="^(?:[0-9]\d*|\d)$"></b-form-input>
                </b-form-group>
                <b-form-group label="Zoll-bis" label-for="inch-bis-input" label-cols="8" label-cols-lg="4">
                    <b-form-input id="arwg-bis-input" v-model="supplierstockpricemarkup.inch_to" type="number" min="0"
                        value="0" step="1" pattern="[0-9]+([\\,|\\.][0-9]+)?" required></b-form-input>
                </b-form-group>
                <b-form-group label="Preisaufschlag1" label-for="markup1-bis-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Preisaufschlag1 ist pflicht">
                    <b-form-input id="markup1-input" v-model="supplierstockpricemarkup.markup1" type="number" min="0"
                        value="0" step="0.01" pattern="[0-9]+([\\,|\\.][0-9]+)?" :state="markup1State"
                        required></b-form-input>
                </b-form-group>
                <b-form-group label="Preisaufschlag1-Type" label-for="markup1type-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Aufschlag1-Type ist pflicht">
                    <b-form-select id="markup1type-input" v-model="supplierstockpricemarkup.markup1type"
                        :options="typeoptions" value-field="key" text-field="type" disabled-field="notEnabled"
                        required></b-form-select>
                </b-form-group>
                <b-form-group label="Preisaufschlag2" label-for="markup2-bis-input" label-cols="8" label-cols-lg="4">
                    <b-form-input id="markup2-input" v-model="supplierstockpricemarkup.markup2" type="number" min="0"
                        value="0" step="0.01" pattern="[0-9]+([\\,|\\.][0-9]+)?"></b-form-input>
                </b-form-group>
                <b-form-group label="Preisaufschlag2-Type" label-for="markup2type-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Aufschlag2-Type ist pflicht">
                    <b-form-select id="markdown2type-input" v-model="supplierstockpricemarkup.markup2type"
                        :options="typeoptions" value-field="key" text-field="type"
                        disabled-field="notEnabled"></b-form-select>
                </b-form-group>
                <b-form-group label="Streckenversand" label-for="ispickupprice-switch" label-cols="8" label-cols-lg="4">
                    <b-icon icon="exclamation-circle-fill" variant="danger" id="tooltip-target-1"></b-icon>
                    <b-tooltip target="tooltip-target-1" triggers="hover">
                        <i style="color:red">Steckenversandkosten (muss aktiviert werden wenn der Aufschlag auch für Abholer gelten soll)!</i>
                    </b-tooltip>
                    <i style="color:red">Steckenversandkosten (muss aktiviert werden wenn der Aufschlag auch für Abholer gelten soll)!</i>

                    <b-form-checkbox switch id="ispickupprice-switch" v-model="supplierstockpricemarkup.ispickupprice" 
                    value="1"
                    unchecked-value="0"
                    >{{ supplierstockpricemarkup.ispickupprice > 0 ? "Es gilt nur für die Abholpreise": "" }}</b-form-checkbox>
                </b-form-group>
                <b-form-group label="Beschreibung" label-for="description-input" label-cols="8" label-cols-lg="4">
                    <b-form-input id="description-input" v-model="supplierstockpricemarkup.description"></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped supplierstockpricemarkup-table" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Lagerbestand</th>
                        <th>Hersteller</th>
                        <th>ARWG-Von</th>
                        <th>ARWG-Bis</th>
                        <th>Zoll-Von</th>
                        <th>Zoll-Bis</th>
                        <th>Aufschlag1</th>
                        <th>Aufschlag1-Type</th>
                        <th>Aufschlag2</th>
                        <th>Aufschlag2-Type</th>
                        <th>Streckenversand</th>
                        <th>Beschreibung</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
        <template>
            <v-row>
                <v-col cols="3">
                    <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        value: Array,
        editdata: null,
        url: {
            type: String,
            default: '/admin/supplierstockpricemarkups'
        },
        location_url: {
            type: String,
            default: '/admin/supplierstockpricemarkup/'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
        headers: {
            default: null
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        modalname: "Preisabschlag hinzufügen",
        headerBgVariant: 'success',
        headerTextVariant: 'light',
        disableOk: false,
        herstellers: {},
        supplierstocks: {},
        onlynumberspattern: /^[0-9]*$/,
        supplierstockpricemarkup: {
            id: 0,
            supplierstocktemplate_id: 0,
            hersteller_number: null,
            productgroup_from: null,
            productgroup_to: null,
            inch_from: null,
            inch_to: null,
            markup1: null,
            markup1type: 'Preis',
            markup2: null,
            markup2type: 'Preis',
            ispickupprice: 0,
            description: null
        },
        // typeoptions:[ {key: 'Preis', type: 'Preis'},{key: 'Prozent', type: 'Prozent'},],
    }),

    created() {
        this.typeoptions = this.$func.getValueTypeOptions();
        this.supplierstockpricemarkup.supplierstocktemplate_id = this.editdata.id;
        this.getHerstellers();
        this.getSupplierStockPricemarkups('/admin/supplierstockpricemarkup/getsupplierstockpricemarkups/' + this.editdata.id);
    },
    mounted() {
        window.supplierstockpricemarkups = this;
    },
    methods: {
        back: function () {
            window.location = location.protocol + "//" + location.hostname + "/admin/supplier/" + this.editdata.supplier_id + "/supplierstocktemplates";
        },
        async getSupplierStockPricemarkups(address) {
            $(document).ready(function () {
                var table = $('.supplierstockpricemarkup-table').DataTable({
                    processing: false,
                    serverSide: true,
                    searching: true,
                    searchable: true,
                    responsive: true,
                    select: true,
                    pageLength: 50,
                    language: {
                        url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                    },
                    ajax: address,
                    columns: [
                        { data: 'id', name: 'id' },
                        { data: 'supplierstocktemplatename', name: 'supplierstocktemplatename' },
                        { data: 'hersteller', name: 'hersteller' },
                        { data: 'productgroup_from', name: 'productgroup_from' },
                        { data: 'productgroup_to', name: 'productgroup_to' },
                        { data: 'inch_from', name: 'inch_from' },
                        { data: 'inch_to', name: 'inch_to' },
                        { data: 'markup1', name: 'markup1' },
                        { data: 'markup1type', name: 'markup1type' },
                        { data: 'markup2', name: 'markup2' },
                        { data: 'markup2type', name: 'markup2type' },
                        { data: 'ispickupprice', name: 'ispickupprice' },
                        { data: 'description', name: 'description' },
                        { data: 'status', name: 'status' },
                        { data: 'action', name: 'action' },
                    ],
                });
            });
        },

        getHerstellers: function () {
            axios.get('/admin/getallhersteller')
                .then(function (response) {
                    this.herstellers = response.data;
                }.bind(this));
        },

        getEditSupplierStockPricemarkup(edit_id) {
            axios.get('/admin/supplierstockpricemarkups/' + edit_id + '/edit')
                .then(function (response) {
                    this.$bvModal.show('modal-prevent-closing');
                    this.modalname = "Preisaufschlag des Lagerbestandes bearbeiten"
                    this.supplierstockpricemarkup = response.data;
                    this.headerBgVariant = 'primary';
                }.bind(this));
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        window.location = location.protocol + "//" + location.hostname + this.location_url + this.editdata.id;
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },

        getDeleteSupplierStockPricemarkdown(edit_id) {
            const _this = this;
            this.$bvModal.msgBoxConfirm('Möchten Sie diesen Preisaufschlag des Lagerbestandes wirklich löschen??', {
                title: 'WARNUNG',
                headerVariant: "warning",
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        axios.delete(this.url + '/' + edit_id
                        ).then(response => {
                            // _this.callback(response);
                            console.log(response);
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            return valid
        },
        resetModal() {
            this.headerBgVariant = 'success';
            this.modalname = "Neuen Preisaufschlag des Lagerbestandes hinzufügen";
            this.supplierstockpricemarkup.id = 0;
            this.supplierstockpricemarkup.supplierstock_id = this.editdata.id;
            this.supplierstockpricemarkup.hersteller_id = 0;
            this.supplierstockpricemarkup.productgroup_from = null;
            this.supplierstockpricemarkup.productgroup_to = null;
            this.supplierstockpricemarkup.inch_from = null;
            this.supplierstockpricemarkup.inch_to = null;
            this.supplierstockpricemarkup.markup1 = null;
            this.supplierstockpricemarkup.markup1type = 'Preis';
            this.supplierstockpricemarkup.markup2 = null;
            this.supplierstockpricemarkup.markup2type = 'Preis';
            this.supplierstockpricemarkup.ispickupprice = 0;
            this.supplierstockpricemarkup.description = null;
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {
            const _this = this;
            if (this.supplierstockpricemarkup.id > 0) {
                axios.put(this.url + '/' + this.supplierstockpricemarkup.id,
                    this.supplierstockpricemarkup,
                ).then(response => {
                    // _this.callback(response);
                    console.log(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                console.log(this.supplierstockpricemarkup);
                axios.post(this.url,
                    this.supplierstockpricemarkup,
                ).then(response => {
                    // _this.callback(response);
                    console.log(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }

            // Hide the modal manually
            this.$nextTick(() => {
                this.resetModal();
                this.$bvModal.hide('modal-prevent-closing')
            })
        }
    },
    watch: {

    },
    computed: {
        platformtemplateState() {
            return this.supplierstockpricemarkup.platformtemplate_id == 0 ? false : true;
        },
        productgroupFromState() {
            return this.supplierstockpricemarkup.productgroup_from == null ? false : true;
        },

        productgroupToState() {
            return this.supplierstockpricemarkup.productgroup_to == null ? false : true;
        },

        markup1State() {
            return (this.supplierstockpricemarkup.markup1 == null || this.supplierstockpricemarkup.markup1 == "") ? false : true;;
        }
    },
};
</script>
