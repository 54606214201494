<template>
    <v-app>
      <v-data-table
        :headers="headers"
        :items="speditionskosten"
        :search="search"
        :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}'
          }"
        fixed-header
        height="200vh"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Speditionskosten</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              v-model="search"
              label="Suchen"
              prepend-inner-icon="mdi-magnify"
              variant="outlined"
              hide-details
              single-line
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  class="mx-2"
                  fab
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-app-bar
                  dark
                  color="success"
                >
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                </v-app-bar>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <v-autocomplete
                        v-model="editedItem.countrycode"
                        :items="countriesoptions"
                        label="Lieferland*"
                        item-text="country"
                        item-value="code"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-text-field
                        v-model="editedItem.price"
                        label="Preis*"
                        type="number"
                        min="0"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                      cols="12"
                      >
                      <v-text-field
                      label="Beschreibung"
                      v-model="editedItem.description" 
                      hide-details="auto"
                      ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                          <v-switch
                          v-model="editedItem.status"
                          :label="`Spedition ${editedItem.status > 0 ? 'aktiviert': 'deaktiviert'}`"
                          color="green"
                          ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="close"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                  color="primary"
  
                    @click="save"
                  >
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="600px">
              <v-card>
                <v-card-title class="text-h5 text-danger" style="word-break: break-word;">Sind Sie sicher, dass Sie diese Spedition löschen möchten?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                  <v-btn color="red darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.price="{ item }">
          {{ convertDoubleToCurrency(item.price) }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-switch
              v-model="item.status"
              color="green"
              disabled
              label=""
              ></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mr-2"
              color="blue"
              v-bind="attrs"
              v-on="on"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Bearbeiten</span>
        </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click="deleteItem(item)"
            color="red"
          >
            mdi-delete
          </v-icon>
        </template>
        <span>Löschen</span>
      </v-tooltip>
        </template>
      </v-data-table>
      </v-app>
    </template>
    <script>
    export default {
      props: {
          value: Array,
          editdata: null,
          url: {
              type: String,
              default: '/admin/speditionskosten'
          },
          callback: {
                  type: Function,
                  default: () => ({})
              },
              catch: {
                  type: Function,
                  default: () => ({})
              },
              finally: {
                  type: Function,
                  default: () => ({})
              },
      },
  
      data: () => ({
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        headers: [
          {
            text: 'Id',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Lieferland', value: 'country.country' },
          { text: 'Landescode', value: 'country.code' },
          { text: 'Preis', value: 'price' },
          { text: 'Beschreibung', value: 'description' },
          { text: 'Status', value: 'status' },
          { text: 'Aktionen', value: 'actions', sortable:false },
        ],
        search: '',
        success:false,
        countriesoptions: [],
        speditionskosten: [],
        editedItem: {
          id: 0,
          supplier_id: null,
          countrycode: null,
          price: null,
          description: null,
          status: 1,
        },
        defaultItem: {
          id: 0,
          countrycode: null,
          price: null,
          description: null,
          status: 1,
        },
      }),
  
      computed: {
        formTitle () {
          return this.editedItem.id === 0 ? 'Spedition hinzufügen' : 'Spedition bearbeiten'
        },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
  
      created () {
        this.getShippingCountries();
        this.getSpeditionskosten();
      },
  
      methods: {
        convertDoubleToCurrency(value) {
        return value === null ? '' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
        },
        async getShippingCountries() {
            axios.get('/admin/getshippingparentcountries')
                .then(function (response) { 
                    this.countriesoptions = response.data;
                }.bind(this));
        },

        async getSpeditionskosten() {
            axios.get('/admin/getspeditionskosten')
                .then(function (response) { 
                    this.speditionskosten = response.data;
                }.bind(this));
        },

        editItem (item) {
          const _this = this;
          axios.get(_this.url+'/'+item.id+'/edit')
              .then(function (response) { 
                  _this.editedItem = response.data;
                  _this.dialog = true
              }.bind(_this)); 
        },
  
        deleteItem (item) {
          const _this = this;
          axios.get(_this.url+'/'+item.id+'/edit')
              .then(function (response) { 
                  _this.editedItem = response.data;
                  _this.dialogDelete = true
              }.bind(_this));
        },
  
        deleteItemConfirm () {
          const _this = this;
          axios.delete(_this.url+'/'+ _this.editedItem.id)
            .then(response => {
              _this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
          _this.closeDelete()
        },
  
        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.editedItem = this.defaultItem; 
          })
        },
  
        closeDelete () {
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = this.defaultItem;
          })
        },
  
        save () {
          const _this = this;
          if(this.editedItem.id > 0){
              axios.put(this.url+'/'+this.editedItem.id,
                  this.editedItem,
              ).then(response => {
                 _this.callback(response);
                  this.showMsgBoxOne(response, true);
              }).catch(response => {
                  _this.catch(response);
              }).finally(response => {
                  _this.finally(response);
              });
          }else{
              axios.post(this.url,
                  this.editedItem,
              ).then(response => {
                  _this.callback(response);
                  this.showMsgBoxOne(response, true);
              }).catch(response => {
                  _this.catch(response);
              }).finally(response => {
                  _this.finally(response);
              });
          }
          // Hide the modal manually
          this.$nextTick(() => {
              this.$bvModal.hide('modal-prevent-closing')
          })
          this.close()
        },
  
        showMsgBoxOne(callback, change) {
          this.$bvModal.msgBoxOk(callback.data)
          .then(value => {
              if(change)
              {
                this.getSpeditionskosten();
              }
          })
          .catch(err => {
              // An error occurred
              console.log(err);
          })
        },
      },
    }
  </script>