<template>
    <v-app>
      <v-data-table
        :headers="headers"
        :items="supplierstockstemplateeanduplications"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [50, 100, 150, -1],
          itemsPerPageAllText: 'Alle',
          itemsPerPageText: 'Datensätze pro Seite',
          pageText: '{0}-{1} von {2}'
        }"
      >
          <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>EAN-Duplizierung der Bestandsvorlage <b>{{ editdata.templateName }}</b></v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              max-width="900px"
            >
              <template v-slot:activator="{ on, attrs }" >
                <v-btn
                  color="success"
                  class="mx-2"
                  fab
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-app-bar
                  dark
                  color="success"
                >
                <v-card-title>
                  <span class="text-h5">EAN-Duplizierung hinzufügen</span>
                </v-card-title>
                </v-app-bar>
                <v-card-text>
                  <v-container>
                    <div class="vue-csv-uploader">
                    <v-form>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                    v-model="editdata.supplier.supplierName"
                                    label="Lieferantenname*"
                                    readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                    v-model="editdata.templateName"
                                    label="Vorlagenname"
                                    :rules="rules.name"
                                    readonly
                                    required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-autocomplete
                                    v-model="editedItem.arhes"
                                    :items="herstellers"
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    label="Hersteller*"
                                    multiple
                                    item-text="name"
                                    item-value="number"
                                ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="editedItem.exportpath"
                                        :items="directories"
                                        label="Exportdateipfad*"
                                        persistent-hint
                                        required
                                    >
                                        <template v-slot:prepend>
                                        <v-tooltip text="Tooltip">
                                            <template v-slot:activator="{ props }">
                                                <v-btn
                                                icon
                                                color="green"
                                                @click="refreshSupplierDirectories"
                                                >
                                                <v-icon v-bind="props">mdi-cached</v-icon>
                                                </v-btn>
                                            </template>
                                            </v-tooltip>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                    v-model="editedItem.exportfilename"
                                    label="Exportdateiname*"
                                    :rules="rules.name"
                                    required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                    label="Beschreibung"
                                    v-model="editedItem.description" 
                                    hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-switch
                                    v-model="editedItem.status"
                                    color="green"
                                    :label="`Duplizierung ${editedItem.status > 0 ? 'aktiviert': 'deaktiviert'}`"
                                    >
                                    </v-switch>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="close"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                  color="primary"
                  @click="save"
                  v-if="editedItem.exportpath && editedItem.exportfilename"
                  >
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="800px">
              <v-card>
                <v-card-title style="color:red;" class="text-h5">Sind Sie sicher, dass Sie diese EAN-Duplikation löschen möchten?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Nein</v-btn>
                  <v-btn color="red darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.supplierName="{ item }">
          {{ editdata.supplier.supplierName }}
        </template>
        <template v-slot:item.templateName="{ item }">
          {{ item.supplierstockstemplate.templateName }}
        </template>
       <template v-slot:item.arhes="{ item }">
            {{ getHerstellerNames(item) }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-switch
              v-model="item.status"
              color="green"
              disabled
              label=""
              ></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            class="mr-2"
            color="blue"
            v-bind="attrs"
            v-on="on"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
        <span>Bearbeiten</span>
      </v-tooltip>
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          v-bind="attrs"
          v-on="on"
          @click="deleteItem(item)"
          color="red"
        >
          mdi-delete
        </v-icon>
        </template>
        <span>Löschen</span>
      </v-tooltip>  
        </template>
      </v-data-table>
      <template>
        <v-row>
          <v-col cols="3">
            <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
          </v-col>
        </v-row> 
      </template>
      </v-app>
  </template>
  <script>
    import axios from 'axios';
    export default {
      props: {
          value: Array,
          editdata: null,
          url: {
              type: String,
              default: '/admin/suppliertemplateeanduplications'
          },
          callback: {
                  type: Function,
                  default: () => ({})
              },
          catch: {
              type: Function,
              default: () => ({})
          },
          finally: {
              type: Function,
              default: () => ({})
          },
          parseConfig: {
                type: Object,
                default() {
                    return {};
                }
            },
            loadBtnText: {
                type: String,
                default: "Laden"
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            autoMatchFields: {
                type: Boolean,
                default: false
            },
            autoMatchIgnoreCase: {
                type: Boolean,
                default: false
            },
            tableClass: {
                type: String,
                default: "table"
            },
            checkboxClass: {
                type: String,
                default: "form-check-input"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },
            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
            canIgnore: {
               type: Boolean,
               default: false,
            }
      },
  
      data: vm => ({
        filenameRules: [
            v => !!v || 'Dateiname ist erforderlich',
        ],

        directories: [],
        herstellers: [],
        supplierstockstemplateeanduplications: [],
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        rules: {
            name: [val => (val || '').length > 0 || 'Erforderlich'],
            number: [val => val > 0 || `Erforderlich`],
          },
        headers: [
          {
            text: 'Id',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Lieferant', value: 'supplierName' },
          { text: 'Vorlagename', value: 'templateName' },
          { text: 'Hersteller', value: 'arhes' },
          { text: 'Exportpfad', value: 'exportpath' },
          { text: 'Exportdateiname', value: 'exportfilename' },
          { text: 'Beschreibung', value: 'description' },
          { text: 'Status', value: 'status' },
          { text: 'Aktionen', value: 'actions', sortable:false },
        ],
        success:false,
        suppliers: [],
        editedItem: {
          id: 0,
          sstemplate_id: vm.editdata.id,
          arhes: [],
          exportpath: null,
          exportfilename: null,
          description: null,
          status: 1,
        },
        defaultItem: {
            id: 0,
            sstemplate_id: vm.editdata.id,
            arhes: [],
            exportpath: null,
            exportfilename: null,
            description: null,
            status: 1,
        },  
      }),  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
  
      created () {
        this.editedItem.supplierstockstemplate_id = this.editdata.id;
        this.defaultItem.supplierstockstemplate_id = this.editdata.id;
        this.getSupplierStocksTemplateEanDuplications();
        this.getDirectories('/admin/supplierstocktemplatedirectories/0');    
        this.getHerstellers();
      },
  
      methods: {
        back: function(){
          window.location = location.protocol + "//" + location.hostname + "/admin/supplier/"+this.editdata.supplier.id+'/supplierstocktemplates';
        },
        
        getDirectories: function(url){
          axios.get(url)
          .then(function (response) {
              this.directories = response.data;
          }.bind(this));
        },
        refreshSupplierDirectories() {
          this.getDirectories('/admin/supplierstocktemplatedirectories/1');
        },

      async getSupplierStocksTemplateEanDuplications() {
        axios.get('/admin/supplierstocktemplate/'+this.editdata.id+'/getsupplierstockstemplateeanduplications')
            .then(function (response) { 
                this.supplierstockstemplateeanduplications = response.data;
            }.bind(this));
      },
        getHerstellers: function(){
            axios.get('/admin/getallhersteller')
            .then(function (response) {
                this.herstellers = response.data;
            }.bind(this));
        },

        getHerstellerNames(item)
        {

            var herstellerlist = "";
            const results = _.filter(this.herstellers, obj => _.includes(item.arhes, obj.number));
            results.forEach(result => {
                if(herstellerlist === ""){
                    herstellerlist = result.name;
                }
                else{
                    herstellerlist = herstellerlist +', ' + result.name;
                } 
            });
            return herstellerlist;
        },
      showSupplierStockTemplateMappings(item){
        let supplierstocktemplatemappings_url = 'admin/supplierstocktemplate/'+item.id+'/supplierstockstemplateeanduplications';
        window.location = location.protocol + "//" + location.hostname + supplierstocktemplatemappings_url;
      },     
      editItem (item) {
        this.editedItem = item;
        this.dialog = true
      },
      deleteItem (item) {
        this.editedItem = item;
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        const _this = this;
        axios.delete(_this.url + '/' + _this.editedItem.id)
            .then(response => {
                _this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });

        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = this.defaultItem; 
        })
      }, 
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = this.defaultItem;
        })
      },
      save () {
        const _this = this;
       
        if(this.editedItem.id > 0){
            axios.put(this.url+'/'+this.editedItem.id,
                this.editedItem,
            ).then(response => {
                _this.callback(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            axios.post(this.url,
                this.editedItem,
            ).then(response => {
                _this.callback(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }
          // Hide the modal manually
          this.$nextTick(() => {
             // this.resetModal();
              this.$bvModal.hide('modal-prevent-closing')
          })
          this.close()
        },
        showMsgBoxOne(callback, change) {
          this.$bvModal.msgBoxOk(callback.data)
          .then(value => {
              if(change)
              {
                this.getSupplierStocksTemplateEanDuplications();
              }
          })
          .catch(err => {
              // An error occurred
              console.log(err);
          })
        },
      },
      computed: {
            formTitle () {
            return this.editedItem.id === 0 ? 'EAN-Duplizierung hinzufügen' : 'EAN-Duplizierung bearbeiten'
            },
        },
    }
  </script>