<template>
    <v-app>
      <v-data-table
        :headers="headers"
        :items="supplierstocktemplatemappings"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [50, 100, 150, -1],
          itemsPerPageAllText: 'Alle',
          itemsPerPageText: 'Datensätze pro Seite',
          pageText: '{0}-{1} von {2}'
        }"
      >
          <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Zuordnung der Lieferantenvorlage <b>{{ editdata.templateName }}</b></v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              max-width="900px"
            >
              <template v-slot:activator="{ on, attrs }" >
                <v-btn
                  color="success"
                  class="mx-2"
                  fab
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                  v-if="supplierstocktemplatemappings.length == 0"
                >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-app-bar
                  dark
                  color="success"
                >
                <v-card-title>
                  <span class="text-h5">Zuordnung hinzufügen</span>
                </v-card-title>
                </v-app-bar>
                <v-card-text>
                  <v-container>
                    <div class="vue-csv-uploader">
                    <v-form>
                      <div class="vue-csv-uploader-part-one">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editdata.supplier.supplierName"
                          label="Lieferantenname*"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                          <v-select
                              v-model="editdata.supplier.supplierType"
                              :items="$func.getSupplierTypeOptions()"
                              label="Lieferantentype*"
                              item-text="text"
                              item-value="value"
                              disabled
                          ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editdata.templateName"
                          label="Vorlagenname*"
                          :rules="rules.name"
                          disabled
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                          <v-text-field
                          v-model="editdata.supplierNumber"
                          label="Lieferantennummer*"
                          :rules="rules.number"
                          type="number"
                          disabled
                          required
                          ></v-text-field>
                      </v-col>
                      </v-row>
                      <v-row>
                      <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.filepath"
                            :items="directories"
                            label="Dateipfad*"
                            persistent-hint
                            required
                          >
                            <template v-slot:prepend>
                              <v-tooltip text="Tooltip">
                                  <template v-slot:activator="{ props }">
                                      <v-btn
                                      icon
                                      color="green"
                                      @click="refreshSupplierDirectories"
                                      >
                                      <v-icon v-bind="props">mdi-cached</v-icon>
                                      </v-btn>
                                  </template>
                                </v-tooltip>
                            </template>
                          </v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                        v-model="editedItem.filename"
                        label="Dateiname*"
                        :rules="rules.name"
                        required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                      <v-row>
                            <v-col cols="4">
                                <v-text-field
                                v-model="editedItem.delimiter"
                                label="Trennzeichen*"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                v-model="editedItem.headerRow"
                                label="Headerzeile*"
                                type="number"
                                min= 1
                                required
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                v-model="editedItem.startRow"
                                label="Startzeile*"
                                type="number"
                                min= 1
                                required
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-file-input
                            v-model="selectedtemplatefile"
                            show-size
                            label="Vorlagedatei*"
                            @change="onUpload"
                            @click:clear="clearSelectedTemplate"
                            prepend-icon="mdi-cloud-upload"
                            id="selectedtemplatefile"
                          ></v-file-input>
                        </v-col>  
                      </v-row>
                      <v-row>
                          <v-col cols="4">
                            <v-text-field
                            v-model="editedItem.price_decimalpoint"
                            label="Preis Dezimaltrennzeichen*"
                            required
                            ></v-text-field>
                          </v-col> 
                      </v-row>
                    </div> <!--End div vue-csv-uploader-part-one-->
                <div class="vue-csv-uploader-part-two"> 
 
                  <div class="vue-csv-mapping" >
                    <table :class="tableClass">
                        <slot name="thead">
                            <thead>
                            <tr>
                                <th width="20">Erforderliche Felder</th>
                                <th width="30">Artikel-Felder</th>
                                <th width="50">Vorlagefelder</th>
                                <th width="50">Abschl.</th>
                            </tr>
                            </thead>
                        </slot>
                        <tbody>
                        <tr v-for="(field, key) in fieldsToMap" :key="key">
                            <td>{{ field.label }}: </td>
                            <td>
                              <v-autocomplete :items="pcart04mapFields" label="" v-model="editedItem.artikel_preis" dense clearable v-if="field.key == 'price_ColumnNr'" item-value="label" item-text="label">
                                  <option selected>Auswählen</option>
                              </v-autocomplete>
                              <v-autocomplete :items="pcart04mapFields" label=""  v-model="editedItem.artikel_tyresInParcel" dense clearable v-if="field.key == 'tyresInParcel_ColumnNr'" item-value="label" item-text="label">
                                  <option selected>Auswählen</option>
                              </v-autocomplete>
                            </td>
                            <td>
                              <select :class="tableSelectClass" :name="`csv_uploader_map_${key}`" v-model="map[field.key]">
                                  <option :value="-1" >Ignorieren</option>
                                  <option v-for="(column, key) in firstRow" :key="key" :value="key">{{ column }}</option>
                              </select>
                            </td>
                            <td>
                              <v-text-field 
                                v-model="editedItem.discount_percentage"
                                type="number"
                                min="0"
                                step="0.1"
                                label="Skonto"
                                prefix="%"
                                dense
                                v-if="field.key == 'price_ColumnNr'"
                              >
                              </v-text-field>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div> <!-- End div vue-csv-mapping-->
                <v-divider></v-divider>
                <v-col cols="12">
                  <v-radio-group row label="Matchingsart:" v-model="editedItem.matchingsart">
                    <v-radio v-for="(item, key) in matchingoptions" :key="item.value" :label="item.text" :value="item.value" ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-row v-if="editedItem.matchingsart == 3">
                  <v-col cols="6" >
                      <v-text-field
                      v-model="editedItem.ARHE"
                      label="Herstellernummer*"
                      :rules="rules.number"
                      type="number"
                      hint="ARHE"
                      clearable
                      required
                      >
                      </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                    label="Gültigkeit(Std)*"
                    v-model="editedItem.validitytime" 
                    type="number"
                    min="0"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-col cols="12">
                  <v-text-field
                  label="Beschreibung"
                  v-model="editedItem.description" 
                  hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="editedItem.lastAction"
                    color="green"
                    :label="`Zuordnung ${editedItem.lastAction > 0 ? 'aktiviert': 'deaktiviert'}`"
              ></v-switch>
                </v-col>
              </div> <!-- End div vue-csv-uploader-part-two-->
                    </v-form>
                    </div>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="close"
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                  color="primary"
                  @click="save"
                  v-if="csv && editedItem.filepath && editedItem.filename"
                  >
                    Speichern
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Sind Sie sicher, dass Sie diesen Lieferant löschen möchten?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.supplierName="{ item }">
          {{ editdata.supplier.supplierName }}
        </template>
        <template v-slot:item.templateName="{ item }">
          {{ item.supplierstocktemplate.templateName }}
        </template>
        <template v-slot:item.filename="{ item }">
          {{ item.filename }}
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description }}
        </template>
        <template v-slot:item.lastAction="{ item }">
          <v-switch
              v-model="item.lastAction"
              color="green"
              disabled
              label=""
              ></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            class="mr-2"
            color="blue"
            v-bind="attrs"
            v-on="on"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
        <span>Bearbeiten</span>
      </v-tooltip>
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          v-bind="attrs"
          v-on="on"
          @click="deleteItem(item)"
          color="red"
        >
          mdi-delete
        </v-icon>
        </template>
        <span>Löschen</span>
      </v-tooltip>  
        </template>
      </v-data-table>
      <template>
        <v-row>
          <v-col cols="3">
            <v-btn elevation="2" rounded small v-on:click="back">Zurück</v-btn>
          </v-col>
        </v-row> 
      </template>
      </v-app>
  </template>
  <script>
    import { drop, every, forEach, get, isArray, map, set } from 'lodash';
    import axios from 'axios';
    import Papa from 'papaparse';
    export default {
      props: {
          value: Array,
          editdata: null,
          url: {
              type: String,
              default: '/admin/supplierstocktemplatemappings'
          },
          mapFields: {
                //required: true
                type: Array,
                default: () => {
                    return ['Artikelnummer', 'Preis', 'Menge', 'Reifen_Pro_Paket', 'EAN', 'Lieferdatum']
                }
            },
          callback: {
                  type: Function,
                  default: () => ({})
              },
          catch: {
              type: Function,
              default: () => ({})
          },
          finally: {
              type: Function,
              default: () => ({})
          },
          parseConfig: {
                type: Object,
                default() {
                    return {};
                }
            },
            loadBtnText: {
                type: String,
                default: "Laden"
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            autoMatchFields: {
                type: Boolean,
                default: false
            },
            autoMatchIgnoreCase: {
                type: Boolean,
                default: false
            },
            tableClass: {
                type: String,
                default: "table"
            },
            checkboxClass: {
                type: String,
                default: "form-check-input"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },
            fileMimeTypes: {
                type: Array,
                default: () => {
                    return ['text/csv','application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'text/x-csv', 'application/vnd.ms-excel', 'text/plain'];
                }
            },
            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
            canIgnore: {
               type: Boolean,
               default: false,
            }
      },
  
      data: () => ({
        form: {
              csv: null,
            },
        filenameRules: [
            v => !!v || 'Dateiname ist erforderlich',
        ],
        fileSelected: false,
        pcart04mapFields:[],
        fieldsToMap: [],
        pcart04fieldsToMap: [],
        map: {},
        hasHeaders: true,
        csv: null,
        sample: null,
        isValidFileMimeType: false,
        selectedtemplatefile:null,
        directories: [],
        supplierstocktemplatemappings: [],
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        rules: {
            name: [val => (val || '').length > 0 || 'Erforderlich'],
            number: [val => val > 0 || `Erforderlich`],
          },
        headers: [
          {
            text: 'Id',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Lieferant', value: 'supplierName' },
          { text: 'Vorlagename', value: 'templateName' },
          { text: 'Vorlagedatei', value: 'filename' },
          { text: 'Beschreibung', value: 'description' },
          { text: 'Status', value: 'lastAction' },
          { text: 'Aktionen', value: 'actions', sortable:false },
        ],
        success:false,
        suppliers: [],
        editedItem: {
          id: 0,
          supplierstockstemplate_id: 0,
          validitytime: 0,
          filepath: null,
          filename: null,
          templateFile: null,
          templateFile_MimeType: null,
          delimiter: ';',
          headerRow: 1,
          startRow: 1,
          templateheader: null,
          supplierArticleNumber: null,
          supplierArticleNumber_ColumnNr: -1,
          price: null,
          price_ColumnNr: -1,
          price_decimalpoint: '.',
          discount_percentage: 0,
          quantity: null,
          quantity_ColumnNr: -1,
          tyresInParcel: null,
          tyresInParcel_ColumnNr: -1,
          EAN: null,
          EAN_ColumnNr: -1,
          matchingsart: 2,
          artikel_preis: null,
          artikel_preis_ColumnNr: -1,
          artikel_tyresInParcel: null,
          artikel_tyresInParcel_ColumnNr: -1,
          deliverydate: null,
          deliverydate_ColumnNr: -1,
          ARHE: null,
          description: null,
          lastAction: 1,
        },
        defaultItem: {
            id: 0,
            supplierstockstemplate_id: 0,
            validitytime: 0,
            filepath: null,
            filename: null,
            templateFile: null,
            templateFile_MimeType: null,
            delimiter: ';',
            headerRow: 1,
            startRow: 1,
            templateheader: null,
            supplierArticleNumber: null,
            supplierArticleNumber_ColumnNr: -1,
            price: null,
            price_ColumnNr: -1,
            price_decimalpoint: '.',
            discount_percentage: 0,
            quantity: null,
            quantity_ColumnNr: -1,
            tyresInParcel: null,
            tyresInParcel_ColumnNr: -1,
            EAN: null,
            EAN_ColumnNr: -1,
            matchingsart: 2,
            artikel_preis: null,
            artikel_preis_ColumnNr: -1,
            artikel_tyresInParcel: null,
            artikel_tyresInParcel_ColumnNr: -1,
            deliverydate: null,
            deliverydate_columnNr: -1,
            ARHE: null,
            description: null,
            lastAction: 1,
        },
        // suppliertypeoptions:[ { value: 0, text: 'Großhändler' },{ value: 1, text: 'Hersteller' },{ value: 2, text: 'Platform' }],
        matchingoptions: [
                            //{ text: 'mit Cartomak', value: '1' },
                            { text: 'mit EAN', value: 2 },
                            { text: 'mit Hersteller-Artikelnummern', value: 3 }
                        ],
      }),
  
      computed: {
        formTitle () {
          return this.editedItem.id === 0 ? 'Zuordnung hinzufügen' : 'Zuordnung bearbeiten'
        },
      },
  
      watch: {
        dialog (val) {
          val || this.close()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
      },
  
      created () {
        this.editedItem.supplierstockstemplate_id = this.editdata.id;
        this.defaultItem.supplierstockstemplate_id = this.editdata.id;
        this.getSupplierStocktTemplateMappings();
        this.getArtikelColumns();
        this.getDirectories('/admin/supplierstocktemplatedirectories/0');
        this.fieldsToMap = [
                            { 'key': 'supplierArticleNumber_ColumnNr', 'label': 'Artikelnummer' },
                            { 'key': 'price_ColumnNr', 'label': 'Preis' },
                            { 'key': 'quantity_ColumnNr', 'label': 'Menge' },
                            { 'key': 'tyresInParcel_ColumnNr', 'label': 'Reifen_Pro_Paket' },
                            { 'key': 'EAN_ColumnNr', 'label': 'EAN' },
                            { 'key': 'deliverydate_ColumnNr', 'label': 'Lieferdatum (Nur bei Massen Adhoc)' },
                          ];
        if (isArray(this.pcart04mapFields)) {
            this.pcart04fieldsToMap = map(this.pcart04mapFields, (item) => {
                return {
                    key: item,
                    label: item
                };
            });
        } else {
            this.pcart04mapFields = map(this.pcart04mapFields, (label, key) => {
                return {
                    key: key,
                    label: label
                };
            });
        }
        this.form.csv = this.buildMappedCsv();
      },
  
      methods: {
        back: function(){
          window.location = location.protocol + "//" + location.hostname + "/admin/supplier/"+this.editdata.supplier.id+'/supplierstocktemplates';
        },
        buildMappedCsv() {
            const _this = this;

            let selectedfields = _.split(this.csv, ',' );
            this.editedItem.templateheader = selectedfields;
            //Artikelnummer
            this.editedItem.supplierArticleNumber_ColumnNr = get(_this.map, 'supplierArticleNumber_ColumnNr');
            this.editedItem.supplierArticleNumber = this.editedItem.supplierArticleNumber_ColumnNr < 0 ? 'undefined' : get(selectedfields, this.editedItem.supplierArticleNumber_ColumnNr);
            //Preis
            this.editedItem.price_ColumnNr = get(_this.map, 'price_ColumnNr');
            this.editedItem.price = this.editedItem.price_ColumnNr < 0 ? null : get(selectedfields, this.editedItem.price_ColumnNr);
            //Menge
            this.editedItem.quantity_ColumnNr = get(_this.map, 'quantity_ColumnNr');
            this.editedItem.quantity = this.editedItem.quantity_ColumnNr < 0 ? null : get(selectedfields, this.editedItem.quantity_ColumnNr);
            //Reifen pro Paket
            this.editedItem.tyresInParcel_ColumnNr = get(_this.map, 'tyresInParcel_ColumnNr');
            this.editedItem.tyresInParcel = this.editedItem.tyresInParcel_ColumnNr < 0 ? null : get(selectedfields, this.editedItem.tyresInParcel_ColumnNr);
            //EAN
            this.editedItem.EAN_ColumnNr = get(_this.map, 'EAN_ColumnNr');
            this.editedItem.EAN = this.editedItem.EAN_ColumnNr < 0 ? null : get(selectedfields, this.editedItem.EAN_ColumnNr);
            //Lieferdatum
            this.editedItem.deliverydate_ColumnNr = get(_this.map, 'deliverydate_ColumnNr');
            this.editedItem.deliverydate = this.editedItem.deliverydate_ColumnNr < 0 ? null : get(selectedfields, this.editedItem.deliverydate_ColumnNr);
         
            let newRow = {};
            let i = 0;
            return map(_this.map, (column, field) => {
                if(column < 0){
                    set(newRow, field, 'Ignorieren');
                }
                else {
                    set(newRow, field, get(_this.editedItem.templateheader, column));
                }
                set(newRow, get(this.fieldsToMap, i).columnnr, column);
                i++;
                return newRow;
            });
        },
        getArtikelColumns: function(){
            axios.get('/admin/getallartikelcolumns')
            .then(function (response) {
                this.pcart04mapFields = response.data;
            }.bind(this));
        },
        getDirectories: function(url){
          axios.get(url)
          .then(function (response) {
              this.directories = response.data;
          }.bind(this));
        },
        refreshSupplierDirectories() {
          this.getDirectories('/admin/supplierstocktemplatedirectories/1');
        },
        async clearSelectedTemplate(){
            const _this = this;
            _this.editedItem.templateFile = null;
            _this.sample = null;
            _this.csv = null;
            _this.editedItem.templateheader=null;
        },
        onUpload() {
          const _this = this;
          try {
              if (_this.selectedtemplatefile) {
                  _this.clearSelectedTemplate();
                  _this.editedItem.templateFile = _this.selectedtemplatefile.name;
                  Papa.parse(_this.selectedtemplatefile, {
                      header : false,
                      preview : 5,
                      skipEmptyLines: true,
                      delimiter: "", // auto-detect
                      newline: "",	 // auto-detect
                      quoteChar: '"',
                      escapeChar: '"',
                      dynamicTyping: true,
                      skipFirstNLines: _this.editedItem.startRow -1,
                      delimitersToGuess: [';', ',', '\t', '|', Papa.RECORD_SEP, Papa.UNIT_SEP],
                      beforeFirstChunk: function(chunk) {
                        return chunk;
                      },
                      complete : function(results,parser) {
                       let list = [];
                       list = get(get(results, "data"), _this.editedItem.headerRow - 1);
                        // _this.sample = set(list, 0, get(get(results, "data"), _this.editedItem.headerRow - 1));
                        // _this.csv = [];
                        _this.sample = [];
                        for (var i = 0; i < list.length; i++) {
                          let result = list[i];
                          // console.log(result);
                          _this.sample[i] =  _.isString(result) ? result.replace(',', '.') : result;
                          result = null;
                        }
                        list = null;
                        _this.csv = _this.sample;
                        console.log(_this.csv);
                      },
                    });
              }
          } catch (error) { 
          }
      },
      async getSupplierStocktTemplateMappings() {
        axios.get('/admin/supplierstocktemplate/'+this.editdata.id+'/getsupplierstocktemplatemappings')
            .then(function (response) { 
                this.supplierstocktemplatemappings = response.data;
            }.bind(this));
      },
      showSupplierStockTemplateMappings(item){
        let supplierstocktemplatemappings_url = 'admin/supplierstocktemplate/'+item.id+'/supplierstocktemplatemappings';
        window.location = location.protocol + "//" + location.hostname + supplierstocktemplatemappings_url;
      },     
      editItem (item) {
        this.editedItem = item;
        this.map = {"supplierArticleNumber_ColumnNr": this.editedItem.supplierArticleNumber_ColumnNr,
                    "price_ColumnNr":this.editedItem.price_ColumnNr,"quantity_ColumnNr":this.editedItem.quantity_ColumnNr,
                    "tyresInParcel_ColumnNr":this.editedItem.tyresInParcel_ColumnNr,"EAN_ColumnNr":this.editedItem.EAN_ColumnNr,
                    "deliverydate_ColumnNr":this.editedItem.deliverydate_ColumnNr
                  };
        this.csv = item.templateheader;
        this.dialog = true
      },
      deleteItem (item) {
        this.editedItem = item;
        this.dialog = true
      },
      deleteItemConfirm () {
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = this.defaultItem; //Object.assign({}, this.defaultItem)
        })
      }, 
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = this.defaultItem;//Object.assign({}, this.defaultItem)
        })
      },
      save () {
        const _this = this;
        this.form.csv = this.buildMappedCsv();
        let csvdata = this.form.csv.shift();
        this.$emit('input', this.form.csv);
        const artikel_price = this.pcart04mapFields.find(element => element.label == this.editedItem.artikel_preis);
        const artikel_tyresInParcel = this.pcart04mapFields.find(element => element.label == this.editedItem.artikel_tyresInParcel);
        this.editedItem.artikel_preis_ColumnNr = artikel_price == null ? null : artikel_price.key;
        this.editedItem.artikel_tyresInParcel_ColumnNr = artikel_tyresInParcel == null ? null : artikel_tyresInParcel.key;
        
        if(this.editedItem.id > 0){
            axios.put(this.url+'/'+this.editedItem.id,
                this.editedItem,
            ).then(response => {
                _this.callback(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            axios.post(this.url,
                this.editedItem,
            ).then(response => {
                _this.callback(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }
          // Hide the modal manually
          this.$nextTick(() => {
             // this.resetModal();
              this.$bvModal.hide('modal-prevent-closing')
          })
          this.close()
        },
        showMsgBoxOne(callback, change) {
          this.$bvModal.msgBoxOk(callback.data)
          .then(value => {
              if(change)
              {
                this.getSupplierStocktTemplateMappings();
              }
          })
          .catch(err => {
              // An error occurred
              console.log(err);
          })
        },
      },
      computed: {
            firstRow() {
                this.editedItem.templateheader = this.editedItem.templateheader == null ? get(this, "sample") : this.editedItem.templateheader;
                return this.editedItem.templateheader;
            },
            pcart04Row() {
                return get(this, "sample");
            },
            showErrorMessage() {
                return this.fileSelected && !this.isValidFileMimeType;
            },

            disabledNextButton() {
                return this.editedItem.templateFile == null;
            },
        },
    }
  </script>