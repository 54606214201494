<template>
    <v-app>
        <v-data-table :headers="headers" :items="marketplaces" :search="search" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [50, 100, 150, -1],
            itemsPerPageAllText: 'Alle',
            itemsPerPageText: 'Datensätze pro Seite',
            pageText: '{0}-{1} von {2}' 
        }" fixed-header height="200vh">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Marktplätze</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" label="Suchen" prepend-inner-icon="mdi-magnify" variant="outlined"
                        hide-details single-line></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="success" class="mx-2" fab dark small v-bind="attrs" v-on="on">
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedItem.name"
                                                label="Markplatzname*"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedItem.path" label="Pfad*"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedItem.customerNumber" label="Kundennummer*">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedItem.apiBaseUrl" label="Basis-URL der Api*"
                                                clearable hint="Die Basis-Url der Marktplatz-Api"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field label="Beschreibung" v-model="editedItem.description"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-switch v-model="editedItem.lastAction"
                                                :label="`Marktplatz ${editedItem.lastAction > 0 ? 'aktiviert' : 'deaktiviert'}`"
                                                color="green" :false-value=0 :true-value=1></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="close">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="primary" @click="save">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogFtp" max-width="600px">
                        <v-card>
                            <v-app-bar dark color="success">
                                <v-card-title>
                                    <span class="text-h5">Ftp-Konfiguration des Marktplatzes</span>
                                </v-card-title>
                            </v-app-bar>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-select v-model="editedFtpItem.type" :items="ftp_options"
                                                label="Ftp-Protokoll*" single-line>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedFtpItem.host" label="Host*"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedFtpItem.port" label="Port*">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedFtpItem.username"
                                                label="Benutzername*"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedFtpItem.password"
                                                label="Kennwort*"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                    v-model="computedDateFormatted"
                                                    label="Gültig bis"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="editedFtpItem.expires_at"
                                                    :active-picker.sync="activePicker"
                                                    min="2023-01-01"
                                                    locale="de-DE"
                                                    @input="menu = false"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedFtpItem.root_dir" label="Pfad*"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="editedFtpItem.config_url" label="Konfigurationswebseite"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field label="Beschreibung" v-model="editedFtpItem.description"
                                                hide-details="auto"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="closeFtpDialog">
                                    Abbrechen
                                </v-btn>
                                <v-btn color="primary" @click="saveFtpConfig">
                                    Speichern
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Sind Sie sicher, dass Sie diesen Marktplatz löschen
                                möchten?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.lastAction="{ item }">
                <v-switch v-model="item.lastAction" color="green" disabled label=""></v-switch>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="green" dark v-bind="attrs" v-on="on" @click="showMarktplaceConfigs(item)">
                            mdi-file-cog
                        </v-icon>
                    </template>
                    <span>Konfiguration des Marktplatzes</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue" dark v-bind="attrs" v-on="on" @click="showMarketplaceFtp(item)">
                            mdi-folder-wrench
                        </v-icon>
                    </template>
                    <span>Ftp des Marktplatzes</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="orange" dark v-bind="attrs" v-on="on"
                            @click="viewMarketplaceCategorieMappings(item)">
                            mdi-file-arrow-up-down-outline
                        </v-icon>
                    </template>
                    <span>Kategorienzuordnung des Marktplatzes</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.createTokenRoute !== null">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="createAccessToken(item)" color="green">
                            mdi-security
                        </v-icon>
                    </template>
                    <span>Zugriffstoken anfordern</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="deleteItem(item)" color="red">
                            mdi-delete
                        </v-icon>
                    </template>
                    <span>Marktplatz löschen</span>
                </v-tooltip>
            </template>
        </v-data-table>
    </v-app>
</template>
<script>
export default {
    props: {
        value: Array,
        url: {
            type: String,
            default: '/admin/marketplaces'
        },
        ftpUrl: {
            type: String,
            default: '/admin/marketplaceftpconfigs'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
    },

    data: () => ({
        activePicker: null,
        menu: false,
        messagedialog: false,
        dialog: false,
        dialogDelete: false,
        dialogFtp:false,
        search: '',
        headers: [
            {
                text: 'Id',
                align: 'start',
                sortable: true,
                value: 'id',
            },
            { text: 'Name', value: 'name' },
            { text: 'Pfad', value: 'path' },
            { text: 'Kundennummer', value: 'customerNumber' },
            { text: 'ApiBaseUrl', value: 'apiBaseUrl' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Status', value: 'lastAction' },
            { text: 'Aktionen', value: 'actions', sortable: false },
        ],
        success: false,
        ftp_options: [ 'FTP', 'SFTP'],
        marketplaces: [],
        editedItem: {
            id: 0,
            name: null,
            path: null,
            customerNumber: null,
            apiBaseUrl: null,
            description: null,
            lastAction: 1,
        },
        defaultItem: {
            id: 0,
            name: null,
            path: null,
            customerNumber: null,
            apiBaseUrl: null,
            description: null,
            lastAction: 1,
        },
        editedFtpItem: {
            id: 0,
            marketplace_id: 0,
            type: 'FTP',
            host: null,
            port: 21,
            username: null,
            password: null,
            expires_at: null,
            root_dir: '/',
            config_url: null,
            description: null
        },
        defaultFtpItem: {
            id: 0,
            marketplace_id: 0,
            type: 'FTP',
            host: null,
            port: 21,
            username: null,
            password: null,
            expires_at: null,
            root_dir: '/',
            config_url: null,
            description: null
        },
        boxOne: '',
    }),

    computed: {
        formTitle() {
            return this.editedItem.id === 0 ? 'Marktplatz hinzufügen' : 'Marktplatz bearbeiten'
        },
        computedDateFormatted() {
            return this.formatDate(this.editedFtpItem.expires_at);
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        dialogFtp(val) {
            val || this.closeFtpDialog()
        }
    },

    created() {
        this.getMarketplaces();
    },

    methods: {
        async getMarketplaces() {
            axios.get('/admin/getmarketplaces')
                .then(function (response) {
                    this.marketplaces = response.data;
                }.bind(this));
        },

        showMarktplaceConfigs(item) {
            let configs_url = '/admin/getmarketplaceconfigs/' + item.id + '/index';
            window.location = location.protocol + "//" + location.hostname + configs_url;
        },

        showMarketplaceFtp(item) {
            const _this = this;
            if (item.marketplaceftpconfig !== null)
            {
                _this.editedFtpItem = item.marketplaceftpconfig;
                _this.defaultFtpItem = item.marketplaceftpconfig;
            } else {
                _this.editedFtpItem.marketplace_id = item.id;
                _this.defaultFtpItem.marketplace_id = item.id;
            }
            
            _this.dialogFtp = true
            // let marketplaceftpconfigs_url = '/admin/marketplaceftpconfigs/' + item.id + '/marketplaceftpconfigsindex';
            // window.location = location.protocol + "//" + location.hostname + marketplaceftpconfigs_url;
        },

        viewMarketplaceCategorieMappings(item) {
            let configs_url = '/admin/getmarketplacecategoriesmapping/' + item.id + '/index';
            window.location = location.protocol + "//" + location.hostname + configs_url;
        },
        createAccessToken(item) {
            window.open(location.protocol + "//" + location.hostname + item.createTokenRoute,
                "", "width=800, height=600");
            // window.location = location.protocol + "//" + location.hostname + item.createTokenRoute;
        },
        editItem(item) {
            axios.get(this.url + '/' + item.id + '/edit')
                .then(function (response) {
                    this.editedItem = response.data;
                    this.dialog = true
                }.bind(this));
        },

        deleteItem(item) {
            axios.get(this.url + '/' + item.id + '/edit')
                .then(function (response) {
                    this.editedItem = response.data;
                    this.dialogDelete = true
                }.bind(this));
        },

        deleteItemConfirm() {
            axios.delete(this.url + '/' + this.editedItem.id)
                .then(response => {
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = this.defaultItem;
            })
        },

        closeFtpDialog() {
            const _this = this;
            _this.dialogFtp = false;
            _this.$nextTick(() => {
                _this.editedFtpItem = _this.defaultFtpItem;
            })
        },

        save() {
            const _this = this;
            if (this.editedItem.id > 0) {
                axios.put(this.url + '/' + this.editedItem.id,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.editedItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }
            // Hide the modal manually
            this.$nextTick(() => {
                // this.resetModal();
                this.$bvModal.hide('modal-prevent-closing')
            })
            this.close()
        },

        saveFtpConfig() {
            const _this = this;
            if (this.editedFtpItem.id > 0) {
                axios.put(this.ftpUrl + '/' + this.editedFtpItem.id,
                    this.editedFtpItem,
                ).then(response => {
                    // _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.ftpUrl,
                    this.editedFtpItem,
                ).then(response => {
                    _this.callback(response);
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }
            // Hide the modal manually
            _this.$nextTick(() => {
                // this.resetModal();
                _this.$bvModal.hide('modal-prevent-closing')
            })
            _this.closeFtpDialog
        },

        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        this.getMarketplaces();
                    }
                })
                .catch(err => {
                })
        },

        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}.${month}.${year}`
        },

        parseDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`
        },
    },
}
</script>