<template>
    <div class="row">
        <b-modal
            id="modal-prevent-closing"
            :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant"
            ref="modal"
            :title="modalname"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            ok-title-html = "Speichern"
            cancel-title = "Abbrechen"
            :ok-disabled="disableOk"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit">

                <b-form-group
                label="Platform"
                label-for="platform-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Platform ist pflicht" 
                >
                    <b-form-select
                    id="platform-input"
                    v-model="wholesalerdefaultpricemarkdown.platformtemplate_id"
                    :options="platformtemplates"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                    :state="platformtemplateState"
                    required
                    disabled
                    ></b-form-select>
                   
                </b-form-group>
                <b-form-group
                label="Hersteller"
                label-for="hersteller-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Hersteller ist pflicht" 
                >
                    <b-form-select
                    id="hersteller-input"
                    v-model="wholesalerdefaultpricemarkdown.hersteller_number"
                    :options="herstellers"
                    value-field="number"
                    text-field="name"
                    disabled-field="notEnabled"
                    required
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="ARWG-Von"
                label-for="arwg-from-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="ARWG-Von ist pflicht" 
                >
                   <b-form-input
                        id="arwg-from-input"
                        v-model="wholesalerdefaultpricemarkdown.productgroup_from" 
                        :state="productgroupFromState"
                        type="number"
                        min="0"
                        value="0"
                        step="1" 
                        pattern="^(?:[0-9]\d*|\d)$"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="ARWG-bis"
                label-for="arwg-bis-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="ARWG-bis ist pflicht" 
                >
                    <b-form-input
                        id="arwg-bis-input"
                        v-model="wholesalerdefaultpricemarkdown.productgroup_to" 
                        :state="productgroupToState"
                        type="number"
                        min="0"
                        value="0"
                        step="1" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                label="Hersteller-Abschlag*"
                label-for="markdown1-bis-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Hersteller-Abschlag ist pflicht" 
                >
                    <b-form-input
                        id="markdown1-input"
                        v-model="wholesalerdefaultpricemarkdown.markdown1" 
                        type="number"
                        min="0"
                        value="0"
                        step="0.01" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        :state="markdown1State"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Hersteller-Abschlagtyp*"
                    label-for="markdown1type-input"
                    label-cols="8"
                    label-cols-lg="4"
                    invalid-feedback="Abschlag1-Type ist pflicht" 
                >
                    <b-form-select
                    id="markdown1type-input"
                    v-model="wholesalerdefaultpricemarkdown.markdown1type"
                    :options="typeoptions"
                    value-field="key"
                    text-field="type"
                    disabled-field="notEnabled"
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="Großhändler-Abschlag*"
                label-for="markdown2-bis-input"
                label-cols="8"
                label-cols-lg="4" 
                invalid-feedback="Großhändler-Abschlag ist pflicht" 
                >
                    <b-form-input
                        id="markdown1-input"
                        v-model="wholesalerdefaultpricemarkdown.markdown2" 
                        type="number"
                        min="0"
                        value="0"
                        step="0.01" 
                        pattern="[0-9]+([\\,|\\.][0-9]+)?"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Großhändler-Abschlagtyp*"
                    label-for="markdown2type-input"
                    label-cols="8"
                    label-cols-lg="4"
                    invalid-feedback="Großhändler-Abschlag ist pflicht" 
                >
                    <b-form-select
                    id="markdown2type-input"
                    v-model="wholesalerdefaultpricemarkdown.markdown2type"
                    :options="typeoptions"
                    value-field="key"
                    text-field="type"
                    disabled-field="notEnabled"
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                label="Beschreibung"
                label-for="description-input"
                label-cols="8"
                label-cols-lg="4"
                invalid-feedback="Beschreibung is required" 
                >
                <b-form-input
                    id="description-input"
                    v-model="wholesalerdefaultpricemarkdown.description" 
                ></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
        <div class="table-responsive">
            <table class="table table-bordered table-striped wholesalerdefaultpricemarkdown-table"  width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Plattformvorlage</th>
                        <th>Hersteller</th>
                        <th>ARWG-Von</th>
                        <th>ARWG-Bis</th>
                        <th>Hersteller-Abschlag</th>
                        <th>Hersteller-Abschlagtyp</th>
                        <th>Großhändler-Abschlag</th>
                        <th>Großhändler-Abschlagtyp</th>
                        <th>Beschreibung</th>
                        <th>Zustand</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
  
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/wholesalerdefaultpricemarkdowns'
            },
            location_url: {
                type: String,
                default: '/admin/wholesalerdefaultpricemarkdown/'
            },
            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            headers: {
                default: null
            },
            submitBtnText: {
                type: String,
                default: "Speichern"
            },
            buttonClass: {
                type: String,
                default: "btn btn-primary"
            },
            inputClass: {
                type: String,
                default: "form-control-file"
            },
            validation: {
                type: Boolean,
                default: true,
            },

            tableSelectClass: {
                type: String,
                default: 'form-control'
            },
        },

        data: () => ({
            modalname:"Abschlag hinzufügen",
            headerBgVariant: 'success',
            headerTextVariant: 'light',
            disableOk:false,
            herstellers:{},
            wholesalers:{},
            platformtemplates:{},
            editshippingservicesurchage:{},
            onlynumberspattern : /^[0-9]*$/,
            wholesalerdefaultpricemarkdown: {
                id: 0,
                platformtemplate_id: 0,
                hersteller_number: null,
                productgroup_from: null,
                productgroup_to: null,
                markdown1: null,
                markdown1type: 'Preis',
                markdown2: null,
                markdown2type: 'Preis',
                description: null
            },
            typeoptions: [{ key: 'Preis', type: 'Preis' }, { key: 'Prozent', type: 'Prozent' },] //$func.getValueTypeOptions(),
        }),

        created() {

            this.wholesalerdefaultpricemarkdown.platformtemplate_id = this.editdata.id;
            this.getHerstellers();
            this.getPlatformtemplates();
            this.getWholesalerDefaultPricemarkdowns('/admin/wholesalerdefaultpricemarkdown/getwholesalerdefaultpricemarkdowns/'+ this.editdata.id);
        },
        mounted() {
             window.wholesalerdefaultpricemarkdowns=this;
        },
        methods: {
            async getWholesalerDefaultPricemarkdowns(address) {
                 $(document).ready(function() {
                    var table = $('.wholesalerdefaultpricemarkdown-table').DataTable({
                        processing: false,
                        serverSide: true,
                        searching: true,
                        searchable:true,
                        responsive: true,
                        select: true,
                        pageLength: 50,
                        language: {
                            url: 'https://cdn.datatables.net/plug-ins/1.10.21/i18n/German.json'
                        },
                        ajax: address,
                        columns: [
                            {data: 'id', name: 'id'}, 
                            {data: 'platformtemplate', name: 'platformtemplate'},
                            {data: 'hersteller', name: 'hersteller'},
                            {data: 'productgroup_from', name: 'productgroup_from'},
                            {data: 'productgroup_to', name: 'productgroup_to'},
                            {data: 'markdown1', name: 'markdown1'},
                            {data: 'markdown1type', name: 'markdown1type'},
                            {data: 'markdown2', name: 'markdown2'},
                            {data: 'markdown2type', name: 'markdown2type'},
                            {data: 'description', name: 'description'},
                            {data: 'status', name: 'status'},
                            {data: 'action', name: 'action'},
                        ],
                    });
                });
            },

            getHerstellers: function(){
                axios.get('/admin/getallhersteller')
                .then(function (response) {
                    this.herstellers = response.data;
                }.bind(this));
            },

            getPlatformtemplates: function(){
                axios.get('/admin/getallplatformtemplates')
                .then(function (response) {
                    this.platformtemplates = response.data;
                }.bind(this));
            },

            getEditWholesalerDefaultPricemarkdown(edit_id){
                axios.get('/admin/wholesalerdefaultpricemarkdowns/'+edit_id+'/edit')
                .then(function (response) { 
                    this.$bvModal.show('modal-prevent-closing');
                    this.modalname = "Standard Abschlag bearbeiten"
                    this.wholesalerdefaultpricemarkdown = response.data;
                    this.headerBgVariant = 'primary';
                }.bind(this));
            },
            showMsgBoxOne(callback, change) {
                this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                   if(change)
                    {
                        $('.wholesalerdefaultpricemarkdown-table').DataTable().ajax.reload();
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },

            getDeleteWholesalerDefaultPricemarkdown(edit_id){
                const _this = this;
                this.$bvModal.msgBoxConfirm('Möchten Sie diesen Standard-Preisabschlag wirklich löschen??', {
                title: 'WARNUNG',
                headerVariant: "warning",
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'JA',
                cancelTitle: 'NEIN',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        axios.delete(this.url+'/'+edit_id
                        ).then(response => {
                            // _this.callback(response);
                            console.log(response);
                            this.showMsgBoxOne(response, true);
                        }).catch(response => {
                            _this.catch(response);
                        }).finally(response => {
                            _this.finally(response);
                        });
                        }
                })
                .catch(err => {
                    // An error occurred
                })
            },

      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        //this.nameState = valid
        return valid
      },
      resetModal() {
        this.headerBgVariant = 'success';
        this.modalname = "Neuen Standard-Preisabschlag hinzufügen";
        this.wholesalerdefaultpricemarkdown.id = 0;
        this.wholesalerdefaultpricemarkdown.platformtemplate_id = this.editdata.id;
        this.wholesalerdefaultpricemarkdown.hersteller_number = null;
        this.wholesalerdefaultpricemarkdown.productgroup_from = null;
        this.wholesalerdefaultpricemarkdown.productgroup_to = null;
        this.wholesalerdefaultpricemarkdown.markdown1 = null;
        this.wholesalerdefaultpricemarkdown.markdown1type = 'Preis';
        this.wholesalerdefaultpricemarkdown.markdown2 = null;
        this.wholesalerdefaultpricemarkdown.markdown2type = 'Preis';
        this.wholesalerdefaultpricemarkdown.description = null;
      },
      handleOk(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.handleSubmit()
      },
      handleSubmit() {
         const _this = this;
        if(this.wholesalerdefaultpricemarkdown.id > 0){
            axios.put(this.url+'/'+this.wholesalerdefaultpricemarkdown.id,
                this.wholesalerdefaultpricemarkdown,
            ).then(response => {
            // _this.callback(response);
            console.log(response);
            this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }else{
            console.log(this.wholesalerdefaultpricemarkdown);
            axios.post(this.url,
                this.wholesalerdefaultpricemarkdown,
            ).then(response => {
                // _this.callback(response);
                console.log(response);
                this.showMsgBoxOne(response, true);
            }).catch(response => {
                _this.catch(response);
            }).finally(response => {
                _this.finally(response);
            });
        }

        // Hide the modal manually
        this.$nextTick(() => {
            this.resetModal();
            this.$bvModal.hide('modal-prevent-closing')
        })
      }
        },
        watch: {

        },
        computed: {
            wholesalerState(){
                return this.wholesalerdefaultpricemarkdown.wholesaler_id == 0 ? false : true;
            },

            platformtemplateState(){
                return this.wholesalerdefaultpricemarkdown.platformtemplate_id == 0 ? false : true;
            },
            productgroupFromState() {
                return this.wholesalerdefaultpricemarkdown.productgroup_from == null ? false : true;
            },

            productgroupToState() {
                return this.wholesalerdefaultpricemarkdown.productgroup_to == null ? false : true;
            },

            markdown1State(){
                return (this.wholesalerdefaultpricemarkdown.markdown1 == null || this.wholesalerdefaultpricemarkdown.markdown1 == "") ? false : true;;
            }
        },
    };
</script>
