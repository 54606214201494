<template>
    <div class="row">
        <b-modal id="modal-ownoffersclient" :header-bg-variant="headerBgVariant"
            :header-text-variant="headerTextVariant" ref="modal" :title="modalname" @show="resetModal"
            @hidden="resetModal" @ok="handleOk" ok-title-html="Speichern" cancel-title="Abbrechen"
            :ok-disabled="disableOk">
            <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="danger" @click="cancel()">
                    Abbrechen
                </b-button>
                <ftp-tester :ftp_protocol="ownoffersclient.sendtype" :ftp_host="ownoffersclient.ftp_host"
                    :ftp_port=ownoffersclient.ftp_port :ftp_username="ownoffersclient.ftp_username"
                    :ftp_password="ownoffersclient.ftp_password"
                    v-if="ownoffersclient.sendtype !== 'LOKAL'"></ftp-tester>
                <b-button size="sm" variant="success" @click="ok()">
                    Speichern
                </b-button>
            </template>
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Kundenname" label-for="name-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Kundenname ist pflicht">
                    <b-form-input id="name-input" v-model="ownoffersclient.name" :state="nameState"
                        required></b-form-input>
                </b-form-group>

                <b-form-group label="Versandart" label-for="sendtype-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Versandart ist pflicht">
                    <b-form-select id="sendtype-input" v-model="ownoffersclient.sendtype" :options="typeoptions"
                        value-field="key" text-field="type" disabled-field="notEnabled" @change="onChangeSendType"
                        required></b-form-select>
                </b-form-group>
                <b-form-group label="FTP-Host" label-for="ftp_host-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="FTP-Host ist pflicht" v-if="ownoffersclient.sendtype !== 'LOKAL'">
                    <b-form-input id="ftp_host-input" v-model="ownoffersclient.ftp_host"
                        :state="ftp_hostState"></b-form-input>
                </b-form-group>
                <b-form-group label="FTP-Port" label-for="ftp_port-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="FTP-Port ist pflicht" v-if="ownoffersclient.sendtype !== 'LOKAL'">
                    <b-form-input id="ftp_port-input" v-model="ownoffersclient.ftp_port" type="number"
                        :state="ftp_portState"></b-form-input>
                </b-form-group>
                <b-form-group label="FTP-Benutzername" label-for="ftp_username-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="FTP-Benutzername ist pflicht" v-if="ownoffersclient.sendtype !== 'LOKAL'">
                    <b-form-input id="ftp_username-input" v-model="ownoffersclient.ftp_username"
                        :state="ftp_usernameState" required></b-form-input>
                </b-form-group>

                <b-form-group label="FTP-Passwort" label-for="ftp_password-input" label-cols="8" label-cols-lg="4"
                    v-if="ownoffersclient.sendtype !== 'LOKAL'" invalid-feedback="FTP-Passwort ist pflicht">
                    <b-form-input id="ftp_password-input" v-model="ownoffersclient.ftp_password"
                        :state="ftp_passwordState" required></b-form-input>
                </b-form-group>
                <b-form-group label="Dateipfad" label-for="ftp_filepath-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Dateipfad ist pflicht">
                    <b-form-input id="ftp_filepath-input" v-model="ownoffersclient.ftp_filepath"
                        :state="ftp_filepathState" required></b-form-input>
                </b-form-group>
                <b-form-group label="Dateiname" label-for="ftp_filename-input" label-cols="8" label-cols-lg="4"
                    invalid-feedback="Dateiname ist pflicht">
                    <b-form-input id="ftp_filename-input" v-model="ownoffersclient.ftp_filename"
                        :state="ftp_filenameState" required></b-form-input>
                </b-form-group>
                <b-form-group label="Beschreibung" label-for="description-input" label-cols="8" label-cols-lg="4">
                    <b-form-input id="description-input" v-model="ownoffersclient.description"></b-form-input>
                </b-form-group>
                <b-form-group label="Plattform-FTP?" label-for="is_platform-checkbox" label-cols="8"
                    label-cols-lg="4">
                    <b-form-checkbox id="is_platform-checkbox" v-model="ownoffersclient.is_platform"
                        name="checkbox_is_platform" value=1 unchecked-value=0>
                        {{ ownoffersclient.is_platform > 0 ? "Ja" : "Nein" }}
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Scheinangebote senden?" label-for="is_send_dummyoffers-checkbox" label-cols="8"
                    label-cols-lg="4">
                    <b-form-checkbox id="is_send_dummyoffers-checkbox" v-model="ownoffersclient.is_send_dummyoffers"
                        name="checkbox_is_send_dummyoffers" value=1 unchecked-value=0>
                        {{ ownoffersclient.is_send_dummyoffers > 0 ? "Ja" : "Nein" }}
                </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Status" label-for="lastaction-checkbox" label-cols="8" label-cols-lg="4">
                    <b-form-checkbox id="lastaction-checkbox" v-model="ownoffersclient.LastAction" name="checkbox-1"
                        value="1" unchecked-value="0">
                        {{ ownoffersclient.LastAction > 0 ? "Aktiviert" : "Deaktiviert" }}
                    </b-form-checkbox>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>
<script>
import axios from 'axios';

export default {

    props: {
        value: Array,
        url: {
            type: String,
            default: '/admin/ownoffersclients'
        },
        callback: {
            type: Function,
            default: () => ({})
        },
        catch: {
            type: Function,
            default: () => ({})
        },
        finally: {
            type: Function,
            default: () => ({})
        },
        headers: {
            default: null
        },
        submitBtnText: {
            type: String,
            default: "Speichern"
        },
        buttonClass: {
            type: String,
            default: "btn btn-primary"
        },
        inputClass: {
            type: String,
            default: "form-control-file"
        },
        validation: {
            type: Boolean,
            default: true,
        },

        tableSelectClass: {
            type: String,
            default: 'form-control'
        },
    },

    data: () => ({
        modalname: "Kunden hinzufügen",
        headerBgVariant: 'success',
        headerTextVariant: 'light',
        disableOk: false,
        enableSubmit: false,
        ownoffersclient: {
            id: 0,
            name: null,
            sendtype: 'FTP',
            ftp_host: null,
            ftp_port: 21,
            ftp_username: null,
            ftp_password: null,
            ftp_filepath: null,
            ftp_filename: null,
            description: null,
            is_platform: 0,
            is_send_dummyoffers: 0,
            LastAction: 1
        },
        typeoptions: [{ key: 'FTP', type: 'FTP' }, { key: 'SFTP', type: 'SFTP' }, { key: 'LOKAL', type: 'LOKAL' }],
    }),

    created() {

    },
    mounted() {
        window.ownoffersclient = this;
    },
    methods: {
        getEditOwnOffersClient(edit_id) {
            axios.get('/admin/ownoffersclients/' + edit_id + '/edit')
                .then(function (response) {
                    this.$bvModal.show('modal-ownoffersclient');
                    this.modalname = "Kunden bearbeiten"
                    this.ownoffersclient = response.data;
                    this.headerBgVariant = 'primary';
                }.bind(this));
        },
        getOwnOffersClientBlockedSupplierStocks(edit_id)
        {
            window.location = location.protocol + "//" + location.hostname + "/admin/ownoffersclientblockedsupplierstocksindex/"+edit_id;
        },
        showMsgBoxOne(callback, change) {
            this.$bvModal.msgBoxOk(callback.data)
                .then(value => {
                    if (change) {
                        this.$emit('reloadtable');
                    }
                })
                .catch(err => {
                    // An error occurred
                })
        },
        onChangeSendType() {
            if (this.ownoffersclient.sendtype == 'FTP') {
                this.ownoffersclient.ftp_port = 21;
            }
            else if (this.ownoffersclient.sendtype == 'SFTP') {
                this.ownoffersclient.ftp_port = 22;
            }
            else {
                this.ownoffersclient.ftp_port = 0;
            }
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            return valid
        },
        resetModal() {
            this.headerBgVariant = 'success';
            this.modalname = "Neuen Kunden hinzufügen";
            this.ownoffersclient.id = 0;
            this.ownoffersclient.name = null;
            this.ownoffersclient.sendtype = 'FTP';
            this.ownoffersclient.ftp_host = null;
            this.ownoffersclient.ftp_port = 21;
            this.ownoffersclient.ftp_username = null;
            this.ownoffersclient.ftp_password = null;
            this.ownoffersclient.ftp_filepath = null;
            this.ownoffersclient.ftp_filename = null;
            this.ownoffersclient.description = null;
            this.ownoffersclient.is_platform= 0;
            this.ownoffersclient.is_send_dummyoffers = 0;
            this.ownoffersclient.LastAction = 1;
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {
            const _this = this;
            if (this.ownoffersclient.id > 0) {
                axios.put(this.url + '/' + this.ownoffersclient.id,
                    this.ownoffersclient,
                ).then(response => {
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            } else {
                axios.post(this.url,
                    this.ownoffersclient,
                ).then(response => {
                    this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            }
            // Hide the modal manually
            this.$nextTick(() => {
                this.resetModal();
                this.$bvModal.hide('modal-ownoffersclient')
            })
        }
    },
    watch: {

    },
    computed: {
        nameState() {
            if (this.ownoffersclient.name == null || this.ownoffersclient.name == "") {
                this.disableOk = true;
                return false;
            }
            this.disableOk = false;
            return true;
        },

        ftp_hostState() {
            if (this.ownoffersclient.sendtype !== 'LOKAL' && (this.ownoffersclient.ftp_host == null || this.ownoffersclient.ftp_host == "")) {
                this.disableOk = true;
                return false;
            }
            this.disableOk = false;
            return true;
        },

        ftp_portState() {
            if (this.ownoffersclient.sendtype !== 'LOKAL' && (this.ownoffersclient.ftp_port == null || this.ownoffersclient.ftp_port == "")) {
                this.disableOk = true;
                return false;
            }
            this.disableOk = false;
            return true;
        },
        ftp_usernameState() {
            if (this.ownoffersclient.sendtype !== 'LOKAL' && (this.ownoffersclient.ftp_username == null || this.ownoffersclient.ftp_username == "")) {
                this.disableOk = true;
                return false;
            }
            this.disableOk = false;
            return true;
        },

        ftp_passwordState() {
            if (this.ownoffersclient.sendtype !== 'LOKAL' && (this.ownoffersclient.ftp_password == null || this.ownoffersclient.ftp_password == "")) {
                this.disableOk = true;
                return false;
            }
            this.disableOk = false;
            return true;
        },

        ftp_filepathState() {
            if (this.ownoffersclient.ftp_filepath == null || this.ownoffersclient.ftp_filepath == "") {
                this.disableOk = true;
                return false;
            }
            this.disableOk = false;
            return true;
        },
        ftp_filenameState() {
            if (this.ownoffersclient.ftp_filename == null || this.ownoffersclient.ftp_filename == "") {
                this.disableOk = true;
                return false;
            }
            this.disableOk = false;
            return true;
        },
    },
};
</script>