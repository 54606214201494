<template>
    <div class="vue-csv-uploader">
        <v-app>
            <div class="table-responsive">
                <v-data-table
                v-model="selectedItems"
                :headers="headers"
                :items="mindestpreise"
                item-key="id"
                show-select
                class="elevation-1"
                :options="footerProps"
                >
                    <template
                    v-slot:top
                    > 
                        <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
                            <v-container
                            class="pa-6"
                            >

                            <v-row>
                                <v-col
                                cols="12"
                                md="3"
                                >
                                <v-text-field
                                dense
                                v-model="profit_data.arwg_von"
                                label="ARWG von"
                                type="number" min="1" step="1"
                                :rules="[rules.required, rules.arwg]"
                                required
                                ></v-text-field>
                                </v-col>

                                <v-col
                                cols="12"
                                md="3"
                                >
                                <v-text-field
                                dense
                                v-model="profit_data.arwg_bis"
                                label="ARWG bis"
                                type="number" min="1" step="1"
                                :rules="[rules.required, rules.arwg]"
                                required
                                ></v-text-field>
                                </v-col>
                                <v-col
                                cols="12"
                                md="3"
                                >
                                <v-text-field
                                dense
                                v-model="profit_data.zusatzpreisgewinn"
                                label="Gewinn"
                                suffix="€"
                                :rules="[rules.required, rules.price]"
                                required
                                ></v-text-field>
                                </v-col>
                                <v-col
                                cols="12"
                                md="3"
                                >
                                    <v-btn
                                    dense
                                    type="submit"
                                    color="success"
                                   
                                    :disabled="!valid || selectedItems.length == 0"
                                    >
                                    Anwenden
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-alert
                                dense
                                type="warning"
                                dismissible
                                v-if="selectedItems.length == 0"
                                >
                                Bitte beachten Sie, dass Sie vor der Anwendung des Zusatzpreisgewinns die entsprechende Mindestpreisangabe auswählen müssen!
                                </v-alert>
                            </v-row>
                            </v-container>
                        </v-form>
                    </template>
                     <template v-slot:header.warengruppeVon="{ header }">
                        {{ header.text }}
                        <v-menu v-model="warengruppeVonFilter" offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="warengruppeVon ? 'primary' : ''">
                                mdi-filter
                                </v-icon>
                            </v-btn>
                            </template>
                            <div style="background-color: white; width: 280px">
                            <v-text-field v-model="warengruppeVon" class="pa-4" type="numeric" label="Geben Sie den Suchbegriff ein"
                                hint="Erlaubte Vorzeichen =, >, >=, <, <= " persistent-hint :autofocus="true"
                                @keydown.enter.prevent="filter">
                            </v-text-field>
                            <v-btn text @click="warengruppeVonFilter = false">
                                <v-icon dark>
                                mdi-close
                                </v-icon>
                            </v-btn>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    <v-btn @click="warengruppeVon = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                    <v-icon dark>
                                        mdi-filter-off
                                    </v-icon>
                                    </v-btn>
                                </span>
                                </template>
                                <span>Filter aufheben</span>
                            </v-tooltip>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                mdi-database-search
                                </v-icon>
                            </v-btn>
                            </div>
                        </v-menu>
                     </template>
                     <template v-slot:header.warengruppeBis="{ header }">
                        {{ header.text }}
                        <v-menu v-model="warengruppeBisFilter" offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon small :color="warengruppeBis ? 'primary' : ''">
                                mdi-filter
                                </v-icon>
                            </v-btn>
                            </template>
                            <div style="background-color: white; width: 280px">
                            <v-text-field v-model="warengruppeBis" class="pa-4" type="numeric" label="Geben Sie den Suchbegriff ein"
                                hint="Erlaubte Vorzeichen =, >, >=, <, <= " persistent-hint :autofocus="true"
                                @keydown.enter.prevent="filter">
                            </v-text-field>
                            <v-btn text @click="warengruppeBisFilter = false">
                                <v-icon dark>
                                mdi-close
                                </v-icon>
                            </v-btn>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    <v-btn @click="warengruppeBis = ''; clearFilter()" small text color="red" class="ml-2 mb-2">
                                    <v-icon dark>
                                        mdi-filter-off
                                    </v-icon>
                                    </v-btn>
                                </span>
                                </template>
                                <span>Filter aufheben</span>
                            </v-tooltip>
                            <v-btn @click="filter" small text color="green" class="ml-2 mb-2" fixed right>
                                <v-icon dark>
                                mdi-database-search
                                </v-icon>
                            </v-btn>
                            </div>
                        </v-menu>
                     </template>
                     <template v-slot:item.preisgewinn="{ item }">
                        {{ convertDoubleToCurrency(item.preisgewinn) }}
                    </template>
                    <template v-slot:item.zusatzpreisgewinn="{ item }">
                        {{ convertDoubleToCurrency(item.zusatzpreisgewinn) }}
                    </template>
                </v-data-table>  
            </div>
        </v-app>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        props: {
            value: Array,
            editdata: null,
            url: {
                type: String,
                default: '/admin/allplatformtemplateminimumprices'
            },

            callback: {
                type: Function,
                default: () => ({})
            },
            catch: {
                type: Function,
                default: () => ({})
            },
            finally: {
                type: Function,
                default: () => ({})
            },
            validation: {
                type: Boolean,
                default: true,
            },
        },

        data: () => ({
            pricemarkdowntypeoptions:['PREIS', 'PROZENT'],
            rules: {
                required: value => !!value || 'Erforderlich',
                counter: value => value.length <= 20 || 'Max 20 characters',
                arwg: value => {
                    return /^\d+$/.test(value) || 'Ungültig'
                },
                price: value => {
                   return /^[\+\-]?\d+(?:[\.,]\d{1,2})?$/.test(value) || 'Ungültig'
                },
            },
            filters: [],
                options: {
                page: 1,
                itemsPerPage: 50,
                sortDesc: [false],
                mustSort: false,
                multiSort: false,
                sort: '',
                filters: null
            },
            enableSubmit: false,
            countryoptions:{},
            warengruppeVon:'',
            warengruppeVonFilter: false,
            warengruppeBis:'',
            warengruppeBisFilter: false,
            arwg_von:null,
            arwg_bis:null,
            gewinn:null,
            valid: false,
            selectedItems: [],
            headers: [
            {text: 'Id', value: 'id'},
            {text: 'Plattform', value: 'platformtemplate.platform.name'},
            { text: 'Plattformvorlage', value: 'platformtemplate.name' },
            { text: 'Min. Menge', value: 'anzahl' },
            { text: 'ARWG von', value: 'warengruppeVon' },
            { text: 'ARWG bis', value: 'warengruppeBis' },
            { text: 'Preis (Gewinn)', value: 'preisgewinn' },
            { text: 'Zusatzpreisgewinn', value: 'zusatzpreisgewinn' },
            { text: 'Startdatum', value: 'display_validdatefrom' },
            { text: 'Enddatum', value: 'display_validdateuntil' },
            { text: 'Beschreibung', value: 'description' },
            { text: 'Aktionen', value: 'actions' },
            ],

            footerProps: {
            'items-per-page-options': [-1],
            'show-current-page': true,
            'show-first-last-page': true,
            disableItemsPerPage: false,
            itemsPerPage: -1,
            itemsPerPageText: 'Einträge pro Seite',
            itemsPerPageAllText: 'Alle',
            },

            profit_data:{
                arwg_von: null,
                arwg_bis: null,
                zusatzpreisgewinn: null,
                selected_minumumprices_ids:[]
            },
            mindestpreise: [],
        }),

        created() {
            this.filters = new Array();
            this.getPlatformtemplateMindestpreise();
        },

        methods: {
            async clearFilter() {
                this.filter();
            },
            convertDoubleToCurrency(value) {
                return value === null ? '' : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value)
            },
            getPlatformtemplateMindestpreise: function(){
                axios.get('/admin/getallplatformtemplateminimumprices/', { params: this.options })
                .then(function (response) {
                    this.mindestpreise = response.data;
                }.bind(this));
            },

            submitForm() {
                const _this = this;
                if(_this.selectedItems.length == 0)
                {
                    var callback = {
                        data: "Die passende Mindestpreise müssen selektiert werden!",
                        status: 400,
                        typ: 'warning'
                    }
                    _this.showMsgBoxOne(callback, false);
                    return;
                }
                _this.profit_data.selected_minumumprices_ids = _this.selectedItems.map(item => item.id);
                axios.post(_this.url,
                     _this.profit_data,
                ).then(response => {
                   _this.showMsgBoxOne(response, true);
                }).catch(response => {
                    _this.showMsgBoxOne(response, false);
                     _this.catch(response);
                }).finally(response => {
                    _this.finally(response);
                });
            },

            showMsgBoxOne(callback, change) {
                if(callback.status > 200){
                        this.$bvModal.msgBoxOk(callback.data, {
                        title: 'FEHLER',
                        headerBgVariant: 'danger',
                        headerTextVariant: 'light',
                        titleVariant: 'danger',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'danger',
                        size:'lg',
                        okVariant: 'warning'
                         });
                    return;
                }
                this.$bvModal.msgBoxOk(callback.data, {
                        title: 'MELDUNG',
                        headerBgVariant: 'success',
                        headerTextVariant: 'light',
                        titleVariant: 'success',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'success',
                        size:'lg',
                        okVariant: 'success'
                         })
                .then(value => {
                    if(change)
                    {
                       this.getPlatformtemplateMindestpreise();
                    }
                })
                .catch(err => {
                   
                })
            },

            showError(mp) {
                if(mp.prozent == null)
                 {
                     this.$bvModal.msgBoxOk('Prozent darf nicht leer sein!'+ mp, {
                        title: 'FEHLER',
                        headerBgVariant: 'danger',
                        headerTextVariant: 'light',
                        titleVariant: 'danger',
                        //bodyBgVariant: 'warning',
                        bodyTextVariant:'danger',
                        size:'lg',
                        okVariant: 'warning'
                         });
                 }
            },

            validate () {
                this.$refs.form.validate()
            },

            filter() {
                this.filters.length = 0;

                if (this.platform) {
                    this.filters.push(new Array('platformtemplate_id', '=', this.platform));
                }
                if (this.warengruppeVon) {
                    this.filters.push(new Array('warengruppeVon', '=', this.warengruppeVon));
                }
                if (this.warengruppeBis) {
                    this.filters.push(new Array('warengruppeBis', '=', this.warengruppeBis));
                }
                this.options.filters = this.filters;
                this.getPlatformtemplateMindestpreise();
            }
        },
        watch: {

        },
        computed: {

        },
    };
</script>
